export function processAccountsData(tmpMsxLiteAccountData: any[], useSearchAPI: boolean) {
    tmpMsxLiteAccountData.forEach((item: any) => {
        item["msxLiteSortOrder"] = 100;
        item["ParentingLevelSortOrder"] = 0;

        if (useSearchAPI) {
            if (item.msp_endcustomersegmentcode.length > 0) {
                if (useSearchAPI) {
                    if (AccountSegmentSort[item.msp_endcustomersegmentcode[0] as keyof typeof AccountSegmentSort] !== undefined) {
                        item["msxLiteSortOrder"] = AccountSegmentSort[item.msp_endcustomersegmentcode[0] as keyof typeof AccountSegmentSort];
                    }
                    if (ParentingLevelSort[item.msp_endcustomersegmentcode[0] as keyof typeof ParentingLevelSort] !== undefined) {
                        item["ParentingLevelSortOrder"] = ParentingLevelSort[item.msp_endcustomersegmentcode[0] as keyof typeof ParentingLevelSort];
                    }
                }
            }
            if (item.msp_parentinglevelcode.length > 0) {
                if (ParentingLevelSort[item.msp_parentinglevelcode[0] as keyof typeof ParentingLevelSort] !== undefined) {
                    item["ParentingLevelSortOrder"] = ParentingLevelSort[item.msp_parentinglevelcode[0] as keyof typeof ParentingLevelSort];
                }
            }
        }
        else {
            if (item["msp_endcustomersegmentcode@OData.Community.Display.V1.FormattedValue"]) {
                if (AccountSegmentSort[item["msp_endcustomersegmentcode@OData.Community.Display.V1.FormattedValue"] as keyof typeof AccountSegmentSort] !== undefined) {
                    item["msxLiteSortOrder"] = AccountSegmentSort[item["msp_endcustomersegmentcode@OData.Community.Display.V1.FormattedValue"] as keyof typeof AccountSegmentSort];
                }
            }
            if (item["msp_parentinglevelcode@OData.Community.Display.V1.FormattedValue"]) {
                if (ParentingLevelSort[item["msp_parentinglevelcode@OData.Community.Display.V1.FormattedValue"] as keyof typeof ParentingLevelSort] !== undefined) {
                    item["ParentingLevelSortOrder"] = ParentingLevelSort[item["msp_parentinglevelcode@OData.Community.Display.V1.FormattedValue"] as keyof typeof ParentingLevelSort];
                }
            }
        }
    });
    // let tmpMsxListSorted = tmpMsxLiteAccountData.sort((a, b) => a.msxLiteSortOrder - b.msxLiteSortOrder);

    // Sort data by Segment and then by parent level
    const tmpMsxListSorted = tmpMsxLiteAccountData.sort((a, b) => {
        // Compare priorities
        const priorityComparison = a.msxLiteSortOrder - b.msxLiteSortOrder;
        if (priorityComparison !== 0) {
            return priorityComparison;
        }
        // If priorities are equal, compare dates
        return a.ParentingLevelSortOrder - b.ParentingLevelSortOrder;
    });
    let tmpconverted = convertToHierarchicalJSON(tmpMsxListSorted, useSearchAPI);
    return tmpconverted;
}

function convertToHierarchicalJSON(simpleData: any[], useSearchAPI: boolean): any[] {
    let tmpMsxLiteAccountPrimaryKeyName = useSearchAPI ? "@search.objectid" : "accountid";
    let tmpMsxLitPparentaccounId = useSearchAPI ? "parentaccountid" : "_parentaccountid_value";
    const map = new Map<string, any>(); // Map to store references to nodes by their IDs
    const result: any[] = [];
    // First pass: Create nodes and populate the map
    simpleData.forEach((item) => {
        let tmpItem = item;
        //tmpItem["_children"] = []
        map.set(item[tmpMsxLiteAccountPrimaryKeyName], tmpItem);
    });
    // Second pass: Link child nodes to their parent nodes
    simpleData.forEach((item) => {
        const node = map.get(item[tmpMsxLiteAccountPrimaryKeyName]);
        try {
            // if (node && item["msp_hq"]) {
            //     console.log("shiva");
            //     result.push(node);
            // }
            // else
            if (node && item[tmpMsxLitPparentaccounId] !== null && item[tmpMsxLitPparentaccounId] !== undefined) {
                const parent = map.get(item[tmpMsxLitPparentaccounId]);
                if (parent) {
                    parent._children = parent._children || [];
                    parent._children.push(node);
                }
                else {
                    //Add even parent does not exist otherwise this record will be missed from results
                    result.push(node);
                }
            } else if (node && item[tmpMsxLitPparentaccounId] === null) {
                // If parentId is not provided, it's a root node
                result.push(node);
            }
            else {
                result.push(node);
            }
        }
        catch {
            result.push(node);
        }

    });
    return result;
}
const AccountSegmentSort = {
    "Strategic Commercial": 1,
    "Strategic Public Sector": 2,
    "Major Commercial": 3,
    "Major Public Sector": 4,
    "Small, Medium & Corporate Commercial": 5,
    "Small, Medium & Corporate Education": 6,
    "Small, Medium & Corporate Government": 7,
    "Small, Medium & Corporate Public Sector": 8,
    "CSO Partner": 9,
    "Default OEM": 10,
    "Hoster": 11,
    "ISV": 12,
    "MS Direct (Rslr)": 13,
    "Network Services": 14,
    "OEM": 15,
    "Reseller": 16,
    "Rslr Global Accounts: EPG": 17,
    "Rslr Online Stores": 18,
    "Services": 19,
    "Unsegmented": 20
}
const ParentingLevelSort = {
    "Top": 1,
    "Child": 2
}
