import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Checkbox, Button } from "@mui/material";
import { OpportunityCols, MilesonteCols, AccountSearchCols } from './../Utils/Constants'
import { SearchEntityOptions } from './../Utils/searchOptions'
import { iSearchOption } from "../Interfaces/iSearchOption";

function MSXUIAutoComplete(props: any) {
  const [val, setVal] = useState<[]>([]);
  const onSelect = (event: any, values: any) => {
    document.getElementById("tblGrid")?.style.removeProperty("display");
    document.getElementById("msx-pcf-shell")?.style.setProperty("display", "none");
    setVal([...(values as [])])
    props.handleParentFun(values);
  }
  useEffect(() => {
    const tmpVals: any = [];
    if (props.options) {
      props.options.forEach((tmpOption: any) => {
        const tmpEntities = SearchEntityOptions.filter((tmp: iSearchOption) => {
          return tmp.id === tmpOption
        });
        if (tmpEntities.length > 0) {
          tmpVals.push(tmpEntities[0]);
        }
      });
      if (tmpVals.length) {
        setVal(tmpVals)
        props.handleParentFun(tmpVals);
      }
    }
  }, [props.options]);

  return (
    <Autocomplete
      value={val}
      multiple
      id="combo-box-demo"
      fullWidth
      options={SearchEntityOptions}
      sx={{ width: "100%" }}
      disableCloseOnSelect
      onChange={onSelect}
      groupBy={(option: any) => option.group}
      getOptionLabel={(option: any) => option.label}
      className="msxui-search-textbox"
      size="small"
      renderInput={(params) => (
        <TextField {...params} fullWidth size="small" variant="outlined" placeholder="Select Entities/Views" className="msxui-search-textbox" hiddenLabel="true" />
      )}
      renderGroup={(params) => (
        <>
          <div className="msxui-autocomplete-group">{params.group}</div>
          {params.children}
        </>
      )}
    />
  );
}

export default MSXUIAutoComplete;
