import * as React from 'react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IButtonProps } from '@fluentui/react/lib/Button';
import { setVirtualParent } from '@fluentui/dom-utilities';
import { FocusTrapZone } from '@fluentui/react/lib/FocusTrapZone';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { SearchEntityOptions } from '../Utils/searchOptions';
import { iSearchOption } from '../Interfaces/iSearchOption';
import { ContextualMenuItemType } from '@fluentui/react/lib/ContextualMenu';
import { IsShellHostedURL } from '../Utils/utils';
import { FEEDBACK_URL } from '../Utils/Constants';

initializeIcons(/* optional base url */);

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

export function MSXCommandBars(props: any) {
    const [enableFocusTrap, setEnableFocusTrap] = React.useState(false);
    const [menus, setMenus] = React.useState<ICommandBarItemProps[]>([]);

    const onChangeEnableFocusTrap = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => {
            setEnableFocusTrap(!!checked);
        },
        [],
    );

    const _farItems: ICommandBarItemProps[] = [
        {
            key: 'feedback',
            text: 'Feedback',
            ariaLabel: 'Feedback',
            iconOnly: true,
            iconProps: { iconName: 'feedback' },
            onClick: () => {
                window.open(FEEDBACK_URL);
            },
        },
    ];

    const onMenuSelect = (values: any) => {
        let tmpSelectedItems: iSearchOption[] = [];
        tmpSelectedItems.push(values);
        props.handleParentFun(tmpSelectedItems);
    }
    const onHomeClick = () => {
        props.homeClick();
    }

    const getMenus = () => {
        let tmpEntities = SearchEntityOptions.filter((tmpOption: iSearchOption) => {
            return (tmpOption.group === "Entities" && !tmpOption.isExternalAPI)
        })
        const _items: ICommandBarItemProps[] = [];

        return _items;

        const tmpHome = {
            key: "Home",
            text: "Home",
            onClick: () => onHomeClick(),
        }
        const tmpNewMenu = {
            key: "Create New",
            text: "Create New",
            iconProps: { iconName: 'Add' },
        }
        const tmpDividerMenu = {
            key: 'divider',
            name: '-',
            itemType: ContextualMenuItemType.Divider,
        }

        // if (!IsShellHostedURL())
        _items.push(tmpHome);

        tmpEntities.forEach((tmpOption: iSearchOption) => {
            let tmpEntityMenu: ICommandBarItemProps = {
                key: tmpOption.id?.toString() ?? "",
                text: tmpOption.label?.toString() ?? "",
                subMenuProps: {
                    items: []
                }
            }
            let tmpViews = SearchEntityOptions.filter((tmpViewOption: iSearchOption) => {
                return tmpViewOption.group === tmpOption.entityName + " Views"
            })

            // tmpEntityMenu.subMenuProps?.items.push(tmpNewMenu);
            // tmpEntityMenu.subMenuProps?.items.push(tmpDividerMenu);

            tmpViews.forEach((tmpViewOption: iSearchOption) => {
                tmpEntityMenu.subMenuProps?.items.push({
                    key: tmpViewOption.id?.toString() ?? "",
                    text: tmpViewOption.label?.toString() ?? "",
                    onClick: () => onMenuSelect(tmpViewOption),
                    iconProps: { iconName: 'View' },
                })
            });
            _items.push(tmpEntityMenu);
        });
        return _items;
    };

    React.useEffect(() => {
        setMenus(getMenus());
    }, []);

    return (
        <FocusTrapZone disabled={!enableFocusTrap}>
            <CommandBar
                items={menus}
                farItems={_farItems}
                ariaLabel="Views Selection"
                primaryGroupAriaLabel="Views Selection"
                farItemsGroupAriaLabel="Views Selection"
            />
        </FocusTrapZone>
    );
};