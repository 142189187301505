import { LogLevel } from "@azure/msal-browser";
import { APPLICATION_ID_URI, CLIENT_ID, D365_ROOT_URL } from './Constants'
export const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};
export const protectedResources = {
  msxlite: {
    path: "/",
    scopes: [APPLICATION_ID_URI + "/user_impersonation"],
  },
  msxcrm: {
    path: "/",
    scopes: [D365_ROOT_URL + "/user_impersonation"],
  }
};
