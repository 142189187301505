export function processOpportunitiesData(tmpMsxLiteData: any[], useSearchAPI: boolean) {

    if (!useSearchAPI)
        return tmpMsxLiteData;

    tmpMsxLiteData.forEach((item: any) => {
        item["msxLiteSortOrder"] = 100;
        if (useSearchAPI) {
            if (item.statecode.length > 0) {
                if (useSearchAPI) {
                    if (OpportunityStatusSort[item.statecode[0] as keyof typeof OpportunityStatusSort] !== undefined) {
                        item["msxLiteSortOrder"] = OpportunityStatusSort[item.statecode[0] as keyof typeof OpportunityStatusSort];
                    }
                }
            }
        }
    });

    // Sort data by priority and then by date
    const tmpMsxListSorted = tmpMsxLiteData.sort((a, b) => {
        // Compare priorities
        const priorityComparison = a.msxLiteSortOrder - b.msxLiteSortOrder;
        if (priorityComparison !== 0) {
            return priorityComparison;
        }
        // If priorities are equal, compare dates
        return new Date(a.estimatedclosedate).getTime() - new Date(b.estimatedclosedate).getTime();
    });

    return tmpMsxListSorted;
}


const OpportunityStatusSort = {
    "Open": 1,
    "Won": 2,
    "Lost": 3
}
