// import ReactDOM from 'react-dom'
// import { App } from './App'

// ReactDOM.render(<App />, document.getElementById('shivaroot'))

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import { IsShellHostedURL } from './Utils/utils';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig, protectedResources } from "../src/Utils/authConfig";
import { Telemetry } from './Telemetry/TelemetryService';

if (window.start_loading == null) {
    window.start_loading = new Date()
}

export const msalInstance = new PublicClientApplication(msalConfig);

function loginRedirect() {
    //console.log("4 loginRedirect: " + accounts.length);
    try {
        const loginRequest = {
            scopes: protectedResources.msxcrm.scopes,
        };
        if (IsShellHostedURL())
            msalInstance.loginPopup(loginRequest)//.loginRedirect(loginRequest);
        else
            msalInstance.loginRedirect(loginRequest);
    } catch (err) {
        console.log("4 " + err);
    }
}
msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload) {
        console.log("inside success");
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
        sessionStorage.setItem("token", payload.accessToken)
    } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        if (event.error?.name === "InteractionRequiredAuthError" && window.location.pathname.startsWith(protectedResources.msxcrm.path)) {
            loginRedirect();
        } else {
            console.log("1-ACQUIRE_TOKEN_FAILURE");
        }
    } else if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error?.name === "BrowserAuthError" && window.location.pathname.startsWith(protectedResources.msxcrm.path)) {
            loginRedirect();
        } else {
            console.log("2-LOGIN FAILURE");
        }
    } else {
        console.log("3-Callback finished");
    }
});
msalInstance
    .handleRedirectPromise()
    .then(() => {
        //if (window.location.pathname.contains(protectedResources.msxcrm.path)) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            loginRedirect();
        }
        // }
    })
    .catch((err) => {
        console.log("5-" + err);
    });

// const root = ReactDOM.createRoot(
//     document.getElementById('shivaroot') as HTMLElement
// );

ReactDOM.render(<React.StrictMode><App pca={msalInstance} TelemetryService={new Telemetry()} /></React.StrictMode>, document.getElementById('MsxLiteSearchContainerDiv'))
