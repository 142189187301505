export const MilestoneData = [
  {
    "@odata.etag": 'W/"44884243852"',
    msp_milestonenumber: "7-500627238",
    msp_name: "M365 BP",
    "msp_workload_FormattedValue":
      "MW: Core Workloads",
    msp_workload: 861980033,
    "msp_commitmentrecommendation_FormattedValue":
      "Uncommitted",
    msp_commitmentrecommendation: 861980000,
    "msp_milestonecategory_FormattedValue":
      "Partner Reported ACR",
    msp_milestonecategory: 861980006,
    "msp_milestonedate_FormattedValue": "7/31/2022",
    msp_milestonedate: "2022-07-31",
    msp_nonrecurring: null,
    "msp_milestonestatus_FormattedValue": "On Track",
    msp_milestonestatus: 861980000,
    msp_milestonestatusreason: null,
    msp_milestoneazurecapacitytype: null,
    msp_milestonepreferredazureregion: null,
    msp_helpneeded: null,
    msp_milestonecomments: null,
    msp_engagementmilestoneid: "92ba8871-f9c4-583d-e5dc-0000009c539c",
  },
  {
    "@odata.etag": 'W/"41279901758"',
    msp_milestonenumber: "7-500510750",
    msp_name: "Lead",
    "msp_workload_FormattedValue":
      "Data: Analytics - New Analytics (ADX)",
    msp_workload: 861980118,
    "msp_commitmentrecommendation_FormattedValue":
      "Uncommitted",
    msp_commitmentrecommendation: 861980000,
    "msp_milestonecategory_FormattedValue":
      "Deal Registration",
    msp_milestonecategory: 861980003,
    "msp_milestonedate_FormattedValue": "6/30/2023",
    msp_milestonedate: "2023-06-30",
    "msp_nonrecurring_FormattedValue": "Yes",
    msp_nonrecurring: 861980000,
    "msp_milestonestatus_FormattedValue": "On Track",
    msp_milestonestatus: 861980000,
    msp_milestonestatusreason: null,
    msp_milestoneazurecapacitytype: null,
    msp_milestonepreferredazureregion: null,
    msp_helpneeded: null,
    msp_milestonecomments: null,
    msp_engagementmilestoneid: "4ecaec4f-ddb6-7b60-eeab-00000b12c185",
  },
  {
    "@odata.etag": 'W/"46442113936"',
    msp_milestonenumber: "7-500098616",
    msp_name: "Implementation - Phase 2",
    "msp_workload_FormattedValue":
      "Infra: Azure Sec - Sentinel",
    msp_workload: 861980108,
    "msp_commitmentrecommendation_FormattedValue":
      "Committed",
    msp_commitmentrecommendation: 861980003,
    "msp_milestonecategory_FormattedValue":
      "Production",
    msp_milestonecategory: 861980002,
    "msp_milestonedate_FormattedValue": "8/25/2021",
    msp_milestonedate: "2021-08-25",
    msp_nonrecurring: null,
    "msp_milestonestatus_FormattedValue":
      "Completed",
    msp_milestonestatus: 861980003,
    msp_milestonestatusreason: null,
    msp_milestoneazurecapacitytype: null,
    msp_milestonepreferredazureregion: null,
    msp_helpneeded: null,
    msp_milestonecomments: null,
    msp_engagementmilestoneid: "78ab21fd-87e0-a389-1f74-000018b3dcb3",
  },
  {
    "@odata.etag": 'W/"46440443943"',
    msp_milestonenumber: "7-500273524",
    msp_name: "SAP BW/4HANA (lift & shift) - PROD",
    "msp_workload_FormattedValue":
      "Infra: SAP on Azure (S/4 HANA)",
    msp_workload: 861980082,
    "msp_commitmentrecommendation_FormattedValue":
      "Committed",
    msp_commitmentrecommendation: 861980003,
    "msp_milestonecategory_FormattedValue":
      "Production",
    msp_milestonecategory: 861980002,
    "msp_milestonedate_FormattedValue": "5/1/2022",
    msp_milestonedate: "2022-05-01",
    msp_nonrecurring: null,
    "msp_milestonestatus_FormattedValue":
      "Completed",
    msp_milestonestatus: 861980003,
    msp_milestonestatusreason: null,
    "msp_milestoneazurecapacitytype_FormattedValue":
      "Dav4/EaV4/DAsv4/EAsv4 (AMD) (Cores); Mv2 (Cores); Azure NetApp Files (ANF) (TB)",
    msp_milestoneazurecapacitytype: "861980040,861980048,861980062",
    "msp_milestonepreferredazureregion_FormattedValue":
      "West Europe - Amsterdam",
    msp_milestonepreferredazureregion: 861980001,
    msp_helpneeded: null,
    msp_milestonecomments: null,
    msp_engagementmilestoneid: "acc7a655-b627-0faf-0f9e-00002f53b958",
  },
  {
    "@odata.etag": 'W/"46440399779"',
    msp_milestonenumber: "7-QRQH3XBP7",
    msp_name: "FY19 Teams June Growth",
    "msp_workload_FormattedValue":
      "MW: Core Workloads",
    msp_workload: 861980033,
    "msp_commitmentrecommendation_FormattedValue":
      "Committed",
    msp_commitmentrecommendation: 861980003,
    "msp_milestonecategory_FormattedValue":
      "Production",
    msp_milestonecategory: 861980002,
    "msp_milestonedate_FormattedValue": "6/29/2019",
    msp_milestonedate: "2019-06-29",
    msp_nonrecurring: null,
    "msp_milestonestatus_FormattedValue":
      "Completed",
    msp_milestonestatus: 861980003,
    msp_milestonestatusreason: null,
    msp_milestoneazurecapacitytype: null,
    msp_milestonepreferredazureregion: null,
    msp_helpneeded: null,
    msp_milestonecomments:
      "Increase of EXO has been seen with role out of groups. This should now slow down as people move to using Teams. ",
    msp_engagementmilestoneid: "5e32ab24-2b3c-4ff8-857f-00004d2a61af",
  },
  
];
