import { IBatchRequest } from "../../Interfaces/IBatchRequest";

export function BatchRequestComposer(BatchId: string, Requests: IBatchRequest[], boolWithPrefer: boolean = false) {
    let sb: string = "";
    let guidBatchID = crypto.randomUUID();
    let intChangeSetCounter = 1;
    Requests.forEach((batchRequest: IBatchRequest) => {
        if (batchRequest.httpMethod != "GET") {
            sb = sb + "--batch_" + guidBatchID.toString() + "\n" + "content-Type: multipart/mixed;boundary=changeset_" + intChangeSetCounter.toString() + "\n" + "\n"
            sb = sb + "--changeset_" + intChangeSetCounter.toString() + "\n" + "Content-Type:application/http" + "\n" + "Content-Transfer-Encoding:binary" + "\n" + "Content-ID:" + batchRequest.contentId + "\n" + "\n";
            sb = sb + batchRequest.httpMethod + " " + batchRequest.uRL + " HTTP/1.1" + "\n";
            sb = sb + "Content-Type: application/json;type=entry" + "\n" + "\n";
            sb = sb + batchRequest.payload + "\n" + "\n"
            sb = sb + "--changeset_" + intChangeSetCounter.toString() + "--" + "\n";
        }
        else {
            sb = sb + "--batch_" + guidBatchID.toString() + "\n";
            sb = sb + "Content-Type:application/http" + "\n" + "Content-Transfer-Encoding:binary" + "\n" + "\n";

            sb = sb + batchRequest.httpMethod + " " + batchRequest.uRL + " HTTP/1.1" + "\n"
            if (boolWithPrefer) {
                sb = sb + "Accept: application/json" + "\n";
                sb = sb + "OData-Version: 4.0" + "\n";
                sb = sb + "OData-MaxVersion: 4.0" + "\n";
                sb = sb + "Prefer: odata.include-annotations=*" + "\n" + "\n";
            }
            else {
                sb = sb + "Accept: application/json" + "\n" + "\n";
            }
        }
        if (intChangeSetCounter == Requests.length) {
            sb = sb + "--batch_" + guidBatchID.toString() + "--";
        }
        intChangeSetCounter = intChangeSetCounter + 1;
    });

    return {
        "key": guidBatchID.toString(),
        "value": sb
    }
}