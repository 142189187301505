import { useEffect, useRef } from "react";
import 'react-tabulator/lib/styles.css'; // required styles
// import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import 'react-tabulator/lib/css/tabulator_simple.min.css'; // theme

import { ReactTabulator } from 'react-tabulator';
import { MSXGridCommandBars } from "../FluentControls/MSXGridCommandBars";
import { STRING_CONSTANTS } from "../Utils/Constants";
import { IsEmpty, IsNumberFieldEmpty } from "../Utils/utils";
import { IEntityDefinition } from "../Interfaces/IEntityDefinition";

export interface IGrid {
  columns: any[];
  items: any[];
  entityMetadata?: any[]
  entityDefinition?: IEntityDefinition
  showCommandBar?: boolean
  hidePagination?: boolean
  OnSave?: (payload: any, startDate: Date) => {}
}

function Grid(props: IGrid) {
  let tableRef: any = useRef(undefined);

  if (document.getElementById("msxui-grid-pagination-holder")) {
    document.getElementById("msxui-grid-pagination-holder")?.replaceChildren()
  }

  const getGridOptions = () => {
    let _gridOptions: any = {
      height: "50vh",
      minHeight: "50vh",
      layoutColumnsOnNewData: true,
      tooltips: true,
      addRowPos: "top",
      history: true,
      resizableRows: true,
      downloadDataFormatter: (data: any) => data,
      downloadReady: (fileContents: any, blob: any) => blob,
      clipboard: true,
      clipboardPasteAction: "replace",
      layout: "fitColumns",
      placeholder: "No Data Available",
      dataTree: true,
      dataTreeStartExpanded: false,
      paginationElement: document.getElementById("msxui-grid-pagination-holder"),
      clipboardCopyConfig: {
        columnHeaders: false, //do not include column headers in clipboard output
        columnGroups: false, //do not include column groups in column headers for printed table
        rowGroups: false, //do not include row groups in clipboard output
        columnCalcs: false, //do not include column calculation rows in clipboard output
        dataTree: false, //do not include data tree in printed table
        formatCells: false, //show raw cell values without formatter
      },
    }

    if (!props.hidePagination) {
      _gridOptions["pagination"] = "local";
      _gridOptions["paginationSize"] = 15;
      _gridOptions["paginationSizeSelector"] = [10, 15, 20, 25]
    };
    return _gridOptions;
  }

  const onSaveClick = () => {
    const dtStart: Date = new Date();
    if (tableRef.current != undefined) {
      let arrModifiedRecords = tableRef.current.getData().filter((record: any) => {
        return record[STRING_CONSTANTS.rowstate] === "Modified";
      });
      let tmpPayloads: any[] = [];
      if (arrModifiedRecords) {
        arrModifiedRecords.forEach((tmpRecord: any) => {
          const tmpURL = (props.entityDefinition?.pluralName ?? "") + "(" + tmpRecord[props.entityDefinition?.primaryKey ?? ""] + ")";
          const tmpContentId = tmpRecord[props.entityDefinition?.primaryColumn ?? ""]
          tmpPayloads.push({
            "contentId": tmpContentId,
            "relativeUrl": tmpURL,
            "httpMethod": "PATCH",
            "payload": getRecordForServerCall(tmpRecord)
          });
        });
        if (props.OnSave)
          props.OnSave(tmpPayloads, dtStart);
        // debugger;
      }
    }
  };

  const getRecordForServerCall = (tmpRecord: any) => {
    let tmpRowToUpdate: any = {};
    props.columns.forEach((tmpCol) => {
      const tmpAttrs = props.entityMetadata?.filter((tmpAttr: any) => {
        return tmpAttr.attributeDescriptor.LogicalName === tmpCol.field
      }) ?? [];
      if (tmpAttrs.length > 0) {
        let tmpFieldDef = tmpAttrs[0];
        if (!tmpCol.readonly) {
          switch (tmpFieldDef.attributeDescriptor.Type) {
            case "money":
              if (!IsNumberFieldEmpty(tmpRecord[tmpCol.field])) {
                tmpRowToUpdate[tmpCol.field] = Number.parseFloat(tmpRecord[tmpCol.field]);
              }
              break;
            case "datetime":
              break;
            case "picklist":
              if (!IsEmpty(tmpRecord[tmpCol.field])) {
                tmpRowToUpdate[tmpCol.field] = Number.parseInt(tmpRecord[tmpCol.field]);
              }
              break;
            default:
              if (!IsEmpty(tmpRecord[tmpCol.field])) {
                tmpRowToUpdate[tmpCol.field] = tmpRecord[tmpCol.field];
              }
              break;
          }
        }
      }

    });
    return tmpRowToUpdate;
  };

  const getData = () => {
    let tmpRecords: any[] = [];
    props.items.forEach((tmpItem: any) => {
      let tmpRecord: any = tmpItem;
      tmpRecord[STRING_CONSTANTS.rowstate] = "";
      if (tmpItem[STRING_CONSTANTS.SearchObjectID])
        tmpRecord[props.entityDefinition?.primaryKey ?? ""] = tmpItem[STRING_CONSTANTS.SearchObjectID];
      else
        tmpRecord[props.entityDefinition?.primaryKey ?? ""] = tmpItem[props.entityDefinition?.primaryKey ?? ""];

      // props.columns.forEach((tmpCol: any) => {
      //   if (Array.isArray(tmpItem[tmpCol.field])) {
      //     if (tmpItem[tmpCol.field].length > 0) {
      //       const tmpAttrs = props.entityMetadata?.filter((tmpAttr: any) => {
      //         return tmpAttr.attributeDescriptor.LogicalName === tmpCol.field
      //       })
      //       if (tmpAttrs) {
      //         if (tmpAttrs?.length > 0) {
      //           const tmpOptionSetText = tmpItem[tmpCol.field][0];
      //           if (tmpOptionSetText.length > 0) {
      //             const tmpOptionSets = tmpAttrs[0].attributeDescriptor.OptionSet.filter((tmpOption: any) => {
      //               return tmpOption.Label === tmpOptionSetText
      //             });
      //             if (tmpOptionSets.length > 0) {
      //               tmpRecord[tmpCol.field] = tmpOptionSets[0]["Value"];
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      //   else {
      //     tmpRecord[tmpCol.field] = tmpItem[tmpCol.field];
      //   }

      // });
      tmpRecords.push(tmpRecord);
    });

    // debugger;

    return tmpRecords;
  }
  const onCellEdited = (cell: any) => {
    cell.getRow().update({ "rowstate": "Modified" });
  };
  return (
    <div className="msxui-grid-parent-div">
      <table style={{ width: "100%" }} className="msxui-grid-parent-div-table">
        {/* {
          props.showCommandBar && (
            <tr>
              <td align="right" className="msxui-gridcommandbar-container">
                <MSXGridCommandBars OnSaveClick={() => {
                  onSaveClick();
                }}></MSXGridCommandBars>
              </td>
            </tr>
          )
        } */}
        <tr>
          <td>
            <ReactTabulator
              onRef={(r) => (tableRef = r)}
              columns={props.columns}
              data={getData()}
              options={getGridOptions()}
              events={{
                cellEdited: onCellEdited,
              }}
            />
          </td>
        </tr>
      </table>
    </div>
  );
}
export default Grid;
