import { IsEmpty } from '../Utils/utils'

export default function formattedValueTooltip(
  cell: any,
  isReadOnly: boolean,
  fieldName: string,
  isNumeric: boolean
) {
  let divText = '---'
  if (isReadOnly) divText = '---'
  if (isNumeric) divText = '---'
  const tmpLookupDisplayVal =
    cell.getData()[fieldName + '@OData.Community.Display.V1.FormattedValue']
  if (!IsEmpty(tmpLookupDisplayVal)) {
    divText = tmpLookupDisplayVal
  } else {
    if (!IsEmpty(cell.getValue())) divText = cell.getValue()
  }
  return divText
}
