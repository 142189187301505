import { ShellHosts } from "./Constants";
export function IsShellHostedURL() {
  let tempReturn = false;
  let tmpULR = window.location.href;
  ShellHosts.forEach((tmpString: string) => {
    if (tmpULR.indexOf(tmpString) > -1) {
      tempReturn = true;
    }
  })
  return tempReturn;
}
export const getEnvUrl = (): string => {
  return window.location.href;
};
export const getHostUrl = (): string => {
  return window.location.host;
};


/* eslint-disable no-console */

export abstract class StringUtils {
  public static stringifyProperties(
    inputProps: { [key: string]: unknown } | null | undefined
  ): { [key: string]: string } {
    if (!inputProps) {
      return {};
    }
    let returnProps: { [key: string]: string } = {};
    Object.keys(inputProps).forEach(function (key: string) {
      returnProps[key] = String(inputProps[key]);
    });

    return returnProps;
  }
}

export function sortOptionSets(obj: any, sort: boolean) {
  var arr = [];
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      arr.push({
        key: prop,
        value: obj[prop],
      });
    }
  }

  if (sort) {
    arr.sort(function (a, b) {
      return a.value.toLowerCase().localeCompare(b.value.toLowerCase());
    });
  }
  return arr;
}

export function IsEmpty(obj: any) {
  if (
    obj === undefined ||
    obj === null ||
    obj?.toString().trim().length === 0 ||
    obj?.toString() === "0"
  )
    return true;
  else return false;
}
export function IsNumberFieldEmpty(obj: any) {
  if (
    obj === undefined ||
    obj === null ||
    obj?.toString().trim().length === 0
  )
    return true;
  else return false;
}

export function MandatoryitleFormatter(title: string) {
  return (
    "<div aria-hidden='true' class='header_required_icon'>*</div> " + title
  );
}

export function IsDropdownChanged(originalValue: any, newValue: any) {
  if (originalValue === null || originalValue === undefined) {
    if (
      newValue === null ||
      newValue === undefined ||
      newValue?.toString() === "0"
    )
      return false;
    else return true;
  }
  return originalValue.toString() !== newValue.toString();
}

export function IsTexChanged(originalValue: any, newValue: any) {
  if (originalValue === null || originalValue === undefined) {
    if (
      newValue === null ||
      newValue === undefined ||
      newValue?.toString().trim().length === 0 ||
      newValue?.toString() === "---"
    )
      return false;
    else return true;
  }
  return originalValue.toString() !== newValue.toString();
}

export function GetEditIconHTML() {
  return "<div class='grid-common-formatter-edit symbolFont Edit-symbol'></div>";
}
export function OpenD365Panel(tmpTpID: any) {
  if (tmpTpID) {
    let tmpPage = "msp_msxhomepage_e01cc?data=%7B%22workspace%22:%22C360%22,%22C360%22:%7B%22tpid%22:%22" + tmpTpID + "%22,%22tabName%22:%22Azure%22%7D,%22version%22:%22v1%22,%22traceID%22:%222c01e459-4483-4714-8e3a-d53266698e7d%22%7D";
    var pageInput = {
      pageType: "custom",
      name: tmpPage,
    };
    var navigationOptions = {
      target: 2,
      position: 2,
      width: { value: 100, unit: "%" },
      title: "MSX Simplified Search"
    };
    // @ts-ignore
    Xrm.Navigation.navigateTo(pageInput, navigationOptions)
      .then(
        function () {
          //alert("After Moving Away");
        }
      ).catch(
        function (error: any) {
          //alert(error);
        }
      );
  }
}
// @ts-ignore
export function OpenD365Form(tmpEntity, tmpEntityId, setShowSpinnerForLoadingD365Form) {
  if (setShowSpinnerForLoadingD365Form) {
    setShowSpinnerForLoadingD365Form(true);
  }
  try {
    var pageInput = {
      pageType: "entityrecord",
      entityName: tmpEntity.toLowerCase(),
      entityId: tmpEntityId
    };
    var navigationOptions = {
      target: 2,
      width: { value: 80, unit: "%" },
      position: 2
    };
    // @ts-ignore
    Xrm.Navigation.navigateTo(pageInput, navigationOptions).then(
      function success() {
        // Run code on success
        if (setShowSpinnerForLoadingD365Form) {
          setShowSpinnerForLoadingD365Form(false);
        }
      },
      function error() {
        // Handle errors
        if (setShowSpinnerForLoadingD365Form) {
          setShowSpinnerForLoadingD365Form(false);
        }
      }
    );
  } catch { }
}
// @ts-ignore
export function OpenD365FormInNewTab(tmpEntity, tmpEntityId, setShowSpinnerForLoadingD365Form) {

  try {
    var pageInput = {
      entityName: tmpEntity.toLowerCase(),
      entityId: tmpEntityId,
      openInNewWindow: true
    };

    // @ts-ignore
    Xrm.Navigation.openForm(pageInput).then(
      function success() {
        // Run code on success
        // if (setShowSpinnerForLoadingD365Form) {
        //   setShowSpinnerForLoadingD365Form(false);
        // }
      },
      function error() {
        // Handle errors
        // if (setShowSpinnerForLoadingD365Form) {
        //   setShowSpinnerForLoadingD365Form(false);
        // }
      }
    );
  } catch { }
}