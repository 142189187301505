import React from "react";
import { OpportunityCols, MilesonteCols, OpportunityViewCols, OpportunitySearchCols } from "../Utils/Constants";
import { OpportunityData } from "../Mock/opportunities";
import { RelatedOpportunityData } from "../Mock/related_opportunities";

import { MilestoneData } from "../Mock/msp_engagementmilestones";
import Grid from "./Grid";
import { IRecord } from "../Interfaces/IRecord";

function View(props: IRecord) {
  const childEntities: any[] = [
    {
      cols: MilesonteCols,
      data: MilestoneData,
      Entity: "msp_engagementmilestone",
      HeaderText: "Related Milestones",
    },
    {
      cols: OpportunitySearchCols,
      data: RelatedOpportunityData,
      Entity: "opportunity",
      HeaderText: "Related Opportunities",
    },
  ];

  const ManageExpandCollapse = (
    currentElement: string,
    nextElement: string
  ) => {
    let tmpCurEle = document.getElementById(currentElement);
    let tmpNextEle = document.getElementById(nextElement);

    let tmpClass = "";

    tmpCurEle?.classList.forEach((tmpcls: string) => {
      if (tmpcls === "collapsible") {
        tmpClass = "collapsible";
      }
      if (tmpcls === "active") {
        tmpClass = "active";
      }
    });

    tmpCurEle?.classList.remove(tmpClass);
    tmpCurEle?.classList.add(tmpClass === "active" ? "collapsible" : "active");

    if (tmpNextEle) {
      if (
        tmpNextEle.style.display === "block" ||
        tmpNextEle.style.display === ""
      ) {
        tmpNextEle.style.display = "None";
      } else {
        tmpNextEle.style.display = "block";
      }
    }
  };

  const AppendText = (left: string, right: string) => {
    return left + right;
  };

  return (
    <div>
      <div
        id="divCreateHeader"
        className="egmtHeader active"
        onClick={(event) => {
          ManageExpandCollapse("divCreateHeader", "divCreateDetail");
        }}
      >
        Opportunity Details
      </div>
      <div className="egmtCreateDivMain" id="divCreateDetail">
        <div className="egmtCreateDivWrapperSectionParent">
          <div className="egmtCreateDivWrapperSection">
            {OpportunityViewCols.map((tmpField: any) => {
              return (
                <div className="egmtCreateDivWrapper">
                  <div role="presentation" className="egmtCreateDiv">
                    <div role="presentation" className="egmtCreateDiv2">
                      <div role="presentation" className="egmtCreateDiv3">
                        <div role="presentation" className="egmtCreateDiv4">
                          {tmpField.title}
                        </div>
                      </div>
                      <div role="presentation" className="egmtCreateDiv9">
                        <div>{props.record[tmpField.field]}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="egmtCreateDivWrapperSection">
            {OpportunityViewCols.map((tmpField: any) => {
              return (
                <div className="egmtCreateDivWrapper">
                  <div role="presentation" className="egmtCreateDiv">
                    <div role="presentation" className="egmtCreateDiv2">
                      <div role="presentation" className="egmtCreateDiv3">
                        <div role="presentation" className="egmtCreateDiv4">
                          {tmpField.title}
                        </div>
                      </div>
                      <div role="presentation" className="egmtCreateDiv9">
                        <div>{props.record[tmpField.field]}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="egmtCreateDivWrapperSection">
            {OpportunityViewCols.map((tmpField: any) => {
              return (
                <div className="egmtCreateDivWrapper">
                  <div role="presentation" className="egmtCreateDiv">
                    <div role="presentation" className="egmtCreateDiv2">
                      <div role="presentation" className="egmtCreateDiv3">
                        <div role="presentation" className="egmtCreateDiv4">
                          {tmpField.title}
                        </div>
                      </div>
                      <div role="presentation" className="egmtCreateDiv9">
                        <div>{props.record[tmpField.field]}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {childEntities.map((entity: any) => {
        return (
          <div className="egmtHeaderChild">
            <div
              id={AppendText("divHeader", entity.Entity)}
              className="egmtHeader collapsible"
              onClick={(event) => {
                ManageExpandCollapse(
                  AppendText("divHeader", entity.Entity),
                  AppendText("divDetail", entity.Entity)
                );
              }}
            >
              {entity.HeaderText}
            </div>
            <div
              className="egmtCreateDivMain"
              style={{ display: "none" }}
              id={AppendText("divDetail", entity.Entity)}
            >
              <Grid columns={entity.cols} items={entity.data} showCommandBar={false} hidePagination={true}></Grid>
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default View;
