export interface ITelemetryConfig {
    readonly AIKey: string;
    readonly TrackedDependencyUrls: string[];
}

export enum SeverityLevel {
    Verbose = 0,
    Information = 1,
    Warning = 2,
    Error = 3,
    Critical = 4,
}

export type BasicTelemetryProps = {
    SessionId: string;
    CorrelationId: string;
}

export type TelemetryEventProps = {
    Source: string;
    Action: string;
    ActionProps?: Object;
    ActionStatus?: string;
    ErrorMessage?: string;
    Duration?: number;  //in milliseconds
    MiscProps?: Object;
}