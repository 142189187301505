export const account_attributes = [
    {
        "attributeDescriptor": {
            "LogicalName": "accountcategorycode",
            "Type": "picklist",
            "DisplayName": "Account Category",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Preferred Customer",
                    "Value": "1"
                },
                {
                    "Label": "Standard",
                    "Value": "2"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "accountclassificationcode",
            "Type": "picklist",
            "DisplayName": "Managed Classification",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Default Value",
                    "Value": "1"
                },
                {
                    "Label": "Prospect",
                    "Value": "495790000"
                },
                {
                    "Label": "Managed",
                    "Value": "495790001"
                },
                {
                    "Label": "Unmanaged",
                    "Value": "495790002"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "accountid",
            "Type": "uniqueidentifier",
            "DisplayName": "Account",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "accountnumber",
            "Type": "string",
            "DisplayName": "Account Number",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "accountratingcode",
            "Type": "picklist",
            "DisplayName": "Account Rating",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Default Value",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_addressid",
            "Type": "uniqueidentifier",
            "DisplayName": "Address 1: ID",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_addresstypecode",
            "Type": "picklist",
            "DisplayName": "Address 1: Address Type",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Bill To",
                    "Value": "1"
                },
                {
                    "Label": "Ship To",
                    "Value": "2"
                },
                {
                    "Label": "Primary",
                    "Value": "3"
                },
                {
                    "Label": "Other",
                    "Value": "4"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_city",
            "Type": "string",
            "DisplayName": "Address 1: City",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_composite",
            "Type": "memo",
            "DisplayName": "Address 1",
            "Format": "2",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_country",
            "Type": "string",
            "DisplayName": "Address 1: Country/Region",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_county",
            "Type": "string",
            "DisplayName": "Address 1: County",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_fax",
            "Type": "string",
            "DisplayName": "Address 1: Fax",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_freighttermscode",
            "Type": "picklist",
            "DisplayName": "Address 1: Freight Terms",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "FOB",
                    "Value": "1"
                },
                {
                    "Label": "No Charge",
                    "Value": "2"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_latitude",
            "Type": "double",
            "DisplayName": "Address 1: Latitude",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_line1",
            "Type": "string",
            "DisplayName": "Address 1: Street 1",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_line2",
            "Type": "string",
            "DisplayName": "Address 1: Street 2",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_line3",
            "Type": "string",
            "DisplayName": "Address 1: Street 3",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_longitude",
            "Type": "double",
            "DisplayName": "Address 1: Longitude",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_name",
            "Type": "string",
            "DisplayName": "Address 1: Name",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_postalcode",
            "Type": "string",
            "DisplayName": "Address 1: ZIP/Postal Code",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_postofficebox",
            "Type": "string",
            "DisplayName": "Address 1: Post Office Box",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_primarycontactname",
            "Type": "string",
            "DisplayName": "Address 1: Primary Contact Name",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_shippingmethodcode",
            "Type": "picklist",
            "DisplayName": "Address 1: Shipping Method",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Airborne",
                    "Value": "1"
                },
                {
                    "Label": "DHL",
                    "Value": "2"
                },
                {
                    "Label": "FedEx",
                    "Value": "3"
                },
                {
                    "Label": "UPS",
                    "Value": "4"
                },
                {
                    "Label": "Postal Mail",
                    "Value": "5"
                },
                {
                    "Label": "Full Load",
                    "Value": "6"
                },
                {
                    "Label": "Will Call",
                    "Value": "7"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_stateorprovince",
            "Type": "string",
            "DisplayName": "Address 1: State/Province",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_telephone1",
            "Type": "string",
            "DisplayName": "Address Phone",
            "Format": "Phone",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_telephone2",
            "Type": "string",
            "DisplayName": "Address 1: Telephone 2",
            "Format": "Phone",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_telephone3",
            "Type": "string",
            "DisplayName": "Address 1: Telephone 3",
            "Format": "Phone",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_upszone",
            "Type": "string",
            "DisplayName": "Address 1: UPS Zone",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address1_utcoffset",
            "Type": "integer",
            "DisplayName": "Address 1: UTC Offset",
            "Format": "2",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_addressid",
            "Type": "uniqueidentifier",
            "DisplayName": "Address 2: ID",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_addresstypecode",
            "Type": "picklist",
            "DisplayName": "Address 2: Address Type",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Default Value",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_city",
            "Type": "string",
            "DisplayName": "Address 2: City",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_composite",
            "Type": "memo",
            "DisplayName": "Address 2",
            "Format": "2",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_country",
            "Type": "string",
            "DisplayName": "Address 2: Country/Region",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_county",
            "Type": "string",
            "DisplayName": "Address 2: County",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_fax",
            "Type": "string",
            "DisplayName": "Address 2: Fax",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_freighttermscode",
            "Type": "picklist",
            "DisplayName": "Address 2: Freight Terms",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Default Value",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_latitude",
            "Type": "double",
            "DisplayName": "Address 2: Latitude",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_line1",
            "Type": "string",
            "DisplayName": "Address 2: Street 1",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_line2",
            "Type": "string",
            "DisplayName": "Address 2: Street 2",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_line3",
            "Type": "string",
            "DisplayName": "Address 2: Street 3",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_longitude",
            "Type": "double",
            "DisplayName": "Address 2: Longitude",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_name",
            "Type": "string",
            "DisplayName": "Address 2: Name",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_postalcode",
            "Type": "string",
            "DisplayName": "Address 2: ZIP/Postal Code",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_postofficebox",
            "Type": "string",
            "DisplayName": "Address 2: Post Office Box",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_primarycontactname",
            "Type": "string",
            "DisplayName": "Address 2: Primary Contact Name",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_shippingmethodcode",
            "Type": "picklist",
            "DisplayName": "Address 2: Shipping Method",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Default Value",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_stateorprovince",
            "Type": "string",
            "DisplayName": "Address 2: State/Province",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_telephone1",
            "Type": "string",
            "DisplayName": "Address 2: Telephone 1",
            "Format": "Phone",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_telephone2",
            "Type": "string",
            "DisplayName": "Address 2: Telephone 2",
            "Format": "Phone",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_telephone3",
            "Type": "string",
            "DisplayName": "Address 2: Telephone 3",
            "Format": "Phone",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_upszone",
            "Type": "string",
            "DisplayName": "Address 2: UPS Zone",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "address2_utcoffset",
            "Type": "integer",
            "DisplayName": "Address 2: UTC Offset",
            "Format": "2",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "aging30",
            "Type": "money",
            "DisplayName": "Aging 30",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "aging30_base",
            "Type": "money",
            "DisplayName": "Aging 30 (Base)",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "aging60",
            "Type": "money",
            "DisplayName": "Aging 60",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "aging60_base",
            "Type": "money",
            "DisplayName": "Aging 60 (Base)",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "aging90",
            "Type": "money",
            "DisplayName": "Aging 90",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "aging90_base",
            "Type": "money",
            "DisplayName": "Aging 90 (Base)",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "businesstypecode",
            "Type": "picklist",
            "DisplayName": "Business Type",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Default Value",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "createdby",
            "Type": "lookup",
            "DisplayName": "Created By",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "createdbyexternalparty",
            "Type": "lookup",
            "DisplayName": "Created By (External Party)",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "createdon",
            "Type": "datetime",
            "DisplayName": "Created On",
            "Format": "datetime",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "createdonbehalfby",
            "Type": "lookup",
            "DisplayName": "Created By (Delegate)",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "creditlimit",
            "Type": "money",
            "DisplayName": "Credit Limit",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "creditlimit_base",
            "Type": "money",
            "DisplayName": "Credit Limit (Base)",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "creditonhold",
            "Type": "boolean",
            "DisplayName": "Credit Hold",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "No",
                    "Value": "0"
                },
                {
                    "Label": "Yes",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "customersizecode",
            "Type": "picklist",
            "DisplayName": "Customer Size",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Default Value",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "customertypecode",
            "Type": "picklist",
            "DisplayName": "Relationship Type",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Competitor",
                    "Value": "1"
                },
                {
                    "Label": "Consultant",
                    "Value": "2"
                },
                {
                    "Label": "Customer",
                    "Value": "3"
                },
                {
                    "Label": "Investor",
                    "Value": "4"
                },
                {
                    "Label": "Partner",
                    "Value": "5"
                },
                {
                    "Label": "Influencer",
                    "Value": "6"
                },
                {
                    "Label": "Press",
                    "Value": "7"
                },
                {
                    "Label": "Prospect",
                    "Value": "8"
                },
                {
                    "Label": "Reseller",
                    "Value": "9"
                },
                {
                    "Label": "Supplier",
                    "Value": "10"
                },
                {
                    "Label": "Vendor",
                    "Value": "11"
                },
                {
                    "Label": "Other",
                    "Value": "12"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "defaultpricelevelid",
            "Type": "lookup",
            "DisplayName": "Product Price List",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "description",
            "Type": "memo",
            "DisplayName": "Description",
            "Format": "2",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "donotbulkemail",
            "Type": "boolean",
            "DisplayName": "Do not allow Bulk Emails",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Allow",
                    "Value": "0"
                },
                {
                    "Label": "Do Not Allow",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "donotbulkpostalmail",
            "Type": "boolean",
            "DisplayName": "Do not allow Bulk Mails",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "No",
                    "Value": "0"
                },
                {
                    "Label": "Yes",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "donotemail",
            "Type": "boolean",
            "DisplayName": "Do not allow Emails",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Allow",
                    "Value": "0"
                },
                {
                    "Label": "Do Not Allow",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "donotfax",
            "Type": "boolean",
            "DisplayName": "Do not allow Faxes",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Allow",
                    "Value": "0"
                },
                {
                    "Label": "Do Not Allow",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "donotphone",
            "Type": "boolean",
            "DisplayName": "Do not allow Phone Calls",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Allow",
                    "Value": "0"
                },
                {
                    "Label": "Do Not Allow",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "donotpostalmail",
            "Type": "boolean",
            "DisplayName": "Do not allow Mails",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Allow",
                    "Value": "0"
                },
                {
                    "Label": "Do Not Allow",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "donotsendmm",
            "Type": "boolean",
            "DisplayName": "Send Marketing Materials",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Send",
                    "Value": "0"
                },
                {
                    "Label": "Do Not Send",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "emailaddress1",
            "Type": "string",
            "DisplayName": "Email",
            "Format": "Email",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "emailaddress2",
            "Type": "string",
            "DisplayName": "Email Address 2",
            "Format": "Email",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "emailaddress3",
            "Type": "string",
            "DisplayName": "Email Address 3",
            "Format": "Email",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "entityimageid",
            "Type": "uniqueidentifier",
            "DisplayName": "Entity Image Id",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "exchangerate",
            "Type": "decimal",
            "DisplayName": "Exchange Rate",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "fax",
            "Type": "string",
            "DisplayName": "Fax",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "followemail",
            "Type": "boolean",
            "DisplayName": "Follow Email Activity",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Do Not Allow",
                    "Value": "0"
                },
                {
                    "Label": "Allow",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "ftpsiteurl",
            "Type": "string",
            "DisplayName": "FTP Site",
            "Format": "Url",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "importsequencenumber",
            "Type": "integer",
            "DisplayName": "Import Sequence Number",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "industrycode",
            "Type": "picklist",
            "DisplayName": "Industry (Not Used)",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Accounting",
                    "Value": "1"
                },
                {
                    "Label": "Agriculture and Non-petrol Natural Resource Extraction",
                    "Value": "2"
                },
                {
                    "Label": "Broadcasting Printing and Publishing",
                    "Value": "3"
                },
                {
                    "Label": "Brokers",
                    "Value": "4"
                },
                {
                    "Label": "Building Supply Retail",
                    "Value": "5"
                },
                {
                    "Label": "Business Services",
                    "Value": "6"
                },
                {
                    "Label": "Consulting",
                    "Value": "7"
                },
                {
                    "Label": "Consumer Services",
                    "Value": "8"
                },
                {
                    "Label": "Design, Direction and Creative Management",
                    "Value": "9"
                },
                {
                    "Label": "Distributors, Dispatchers and Processors",
                    "Value": "10"
                },
                {
                    "Label": "Doctor's Offices and Clinics",
                    "Value": "11"
                },
                {
                    "Label": "Durable Manufacturing",
                    "Value": "12"
                },
                {
                    "Label": "Eating and Drinking Places",
                    "Value": "13"
                },
                {
                    "Label": "Entertainment Retail",
                    "Value": "14"
                },
                {
                    "Label": "Equipment Rental and Leasing",
                    "Value": "15"
                },
                {
                    "Label": "Financial",
                    "Value": "16"
                },
                {
                    "Label": "Food and Tobacco Processing",
                    "Value": "17"
                },
                {
                    "Label": "Inbound Capital Intensive Processing",
                    "Value": "18"
                },
                {
                    "Label": "Inbound Repair and Services",
                    "Value": "19"
                },
                {
                    "Label": "Insurance",
                    "Value": "20"
                },
                {
                    "Label": "Legal Services",
                    "Value": "21"
                },
                {
                    "Label": "Non-Durable Merchandise Retail",
                    "Value": "22"
                },
                {
                    "Label": "Outbound Consumer Service",
                    "Value": "23"
                },
                {
                    "Label": "Petrochemical Extraction and Distribution",
                    "Value": "24"
                },
                {
                    "Label": "Service Retail",
                    "Value": "25"
                },
                {
                    "Label": "SIG Affiliations",
                    "Value": "26"
                },
                {
                    "Label": "Social Services",
                    "Value": "27"
                },
                {
                    "Label": "Special Outbound Trade Contractors",
                    "Value": "28"
                },
                {
                    "Label": "Specialty Realty",
                    "Value": "29"
                },
                {
                    "Label": "Transportation",
                    "Value": "30"
                },
                {
                    "Label": "Utility Creation and Distribution",
                    "Value": "31"
                },
                {
                    "Label": "Vehicle Retail",
                    "Value": "32"
                },
                {
                    "Label": "Wholesale",
                    "Value": "33"
                },
                {
                    "Label": "Agriculture, Forestry&Fishing",
                    "Value": "861980000"
                },
                {
                    "Label": "Banking & Capital Markets",
                    "Value": "861980001"
                },
                {
                    "Label": "Discrete Manufacturing",
                    "Value": "861980002"
                },
                {
                    "Label": "Education",
                    "Value": "861980003"
                },
                {
                    "Label": "Government",
                    "Value": "861980004"
                },
                {
                    "Label": "Health",
                    "Value": "861980005"
                },
                {
                    "Label": "Hospitality & Travel",
                    "Value": "861980006"
                },
                {
                    "Label": "Insurance",
                    "Value": "861980007"
                },
                {
                    "Label": "Logistics",
                    "Value": "861980008"
                },
                {
                    "Label": "Media & Cable",
                    "Value": "861980009"
                },
                {
                    "Label": "Nonprofit",
                    "Value": "861980010"
                },
                {
                    "Label": "Other - Unsegmented",
                    "Value": "861980011"
                },
                {
                    "Label": "Power & Utilities",
                    "Value": "861980012"
                },
                {
                    "Label": "Process Mfg & Resources",
                    "Value": "861980013"
                },
                {
                    "Label": "Professional Services",
                    "Value": "861980014"
                },
                {
                    "Label": "Public Safety_Natl Security",
                    "Value": "861980015"
                },
                {
                    "Label": "Retail and Consumer Goods",
                    "Value": "861980016"
                },
                {
                    "Label": "Telecommunications",
                    "Value": "861980017"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "isprivate",
            "Type": "boolean",
            "DisplayName": null,
            "Format": null,
            "OptionSet": [
                {
                    "Label": "No",
                    "Value": "0"
                },
                {
                    "Label": "Yes",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "lastonholdtime",
            "Type": "datetime",
            "DisplayName": "Last On Hold Time",
            "Format": "datetime",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "lastusedincampaign",
            "Type": "datetime",
            "DisplayName": "Last Date Included in Campaign",
            "Format": "date",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "li_companyid",
            "Type": "integer",
            "DisplayName": "LinkedIn Company Id",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "marketcap",
            "Type": "money",
            "DisplayName": "Market Capitalization",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "marketcap_base",
            "Type": "money",
            "DisplayName": "Market Capitalization (Base)",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "marketingonly",
            "Type": "boolean",
            "DisplayName": "Marketing Only",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "No",
                    "Value": "0"
                },
                {
                    "Label": "Yes",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "masterid",
            "Type": "lookup",
            "DisplayName": "Master ID",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "merged",
            "Type": "boolean",
            "DisplayName": "Merged",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "No",
                    "Value": "0"
                },
                {
                    "Label": "Yes",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "modifiedby",
            "Type": "lookup",
            "DisplayName": "Modified By",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "modifiedbyexternalparty",
            "Type": "lookup",
            "DisplayName": "Modified By (External Party)",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "modifiedon",
            "Type": "datetime",
            "DisplayName": "Modified On",
            "Format": "datetime",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "modifiedonbehalfby",
            "Type": "lookup",
            "DisplayName": "Modified By (Delegate)",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msdyn_accountkpiid",
            "Type": "lookup",
            "DisplayName": "KPI",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msdyn_gdproptout",
            "Type": "boolean",
            "DisplayName": "GDPR Optout",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "No",
                    "Value": "0"
                },
                {
                    "Label": "Yes",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msdyn_salesaccelerationinsightid",
            "Type": "lookup",
            "DisplayName": "Sales Acceleration Insights ID",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_accountcreatedby",
            "Type": "lookup",
            "DisplayName": "Account Created By",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_accountmodifiedby",
            "Type": "lookup",
            "DisplayName": "Account Modified By",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_accountnarrative",
            "Type": "memo",
            "DisplayName": "Account Narrative",
            "Format": "1",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_accountnarrativejson",
            "Type": "memo",
            "DisplayName": "Account Narrative JSON",
            "Format": "1",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_accountnumber",
            "Type": "string",
            "DisplayName": "Account Id",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_accountplanid",
            "Type": "lookup",
            "DisplayName": "Account Plan",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_activecontacts",
            "Type": "integer",
            "DisplayName": "Active Contacts",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_activecontacts_date",
            "Type": "datetime",
            "DisplayName": "Active Contacts (Last Updated On)",
            "Format": "datetime",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_activecontacts_state",
            "Type": "integer",
            "DisplayName": "Active Contacts (State)",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_cid",
            "Type": "string",
            "DisplayName": "CID",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_clanumber",
            "Type": "string",
            "DisplayName": "CLA Number",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_critical_conditions",
            "Type": "string",
            "DisplayName": "critical_conditions",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_critical_reason_merged",
            "Type": "string",
            "DisplayName": "Account Alert Reason",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_customermacceligibility",
            "Type": "boolean",
            "DisplayName": "Customer MACC Eligibility",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "false",
                    "Value": "0"
                },
                {
                    "Label": "true",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_digitalnativeidentifier",
            "Type": "multiselectpicklist",
            "DisplayName": "Digital Native Identifier",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Digital Native",
                    "Value": "606820005"
                },
                {
                    "Label": "DS&I Pegasus",
                    "Value": "606820006"
                },
                {
                    "Label": "DS&I Accelerate",
                    "Value": "606820007"
                },
                {
                    "Label": "DS&I FH",
                    "Value": "606820008"
                },
                {
                    "Label": "DS&I Other",
                    "Value": "606820009"
                },
                {
                    "Label": "DS&I AI",
                    "Value": "606820010"
                },
                {
                    "Label": "DS&I Gaming",
                    "Value": "606820011"
                },
                {
                    "Label": "Startup",
                    "Value": "606820012"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_dsraction",
            "Type": "string",
            "DisplayName": "DSRAction",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_dsrdate",
            "Type": "datetime",
            "DisplayName": "DSRDate",
            "Format": "datetime",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_dsrticket",
            "Type": "string",
            "DisplayName": "DSRTicket#",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_dunsid",
            "Type": "string",
            "DisplayName": "DunsID",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_eligibleforunifiedsupport",
            "Type": "boolean",
            "DisplayName": "Eligible for Unified Support",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "No",
                    "Value": "0"
                },
                {
                    "Label": "Yes",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_endcustomersegmentcode",
            "Type": "picklist",
            "DisplayName": "Segment",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Major Commercial",
                    "Value": "861980016"
                },
                {
                    "Label": "Default OEM",
                    "Value": "861980036"
                },
                {
                    "Label": "Hoster",
                    "Value": "861980026"
                },
                {
                    "Label": "ISV",
                    "Value": "861980027"
                },
                {
                    "Label": "MS Direct (Rslr)",
                    "Value": "861980028"
                },
                {
                    "Label": "Network Services",
                    "Value": "861980029"
                },
                {
                    "Label": "OEM",
                    "Value": "861980023"
                },
                {
                    "Label": "Major Public Sector",
                    "Value": "861980017"
                },
                {
                    "Label": "Reseller",
                    "Value": "861980030"
                },
                {
                    "Label": "CSO Partner",
                    "Value": "861980039"
                },
                {
                    "Label": "Rslr Global Accounts: EPG",
                    "Value": "861980032"
                },
                {
                    "Label": "Rslr Online Stores",
                    "Value": "861980033"
                },
                {
                    "Label": "Services",
                    "Value": "861980034"
                },
                {
                    "Label": "Small, Medium & Corporate Commercial",
                    "Value": "861980020"
                },
                {
                    "Label": "Small, Medium & Corporate Education",
                    "Value": "861980021"
                },
                {
                    "Label": "Small, Medium & Corporate Government",
                    "Value": "861980022"
                },
                {
                    "Label": "Strategic Commercial",
                    "Value": "861980019"
                },
                {
                    "Label": "Strategic Public Sector",
                    "Value": "861980018"
                },
                {
                    "Label": "Unsegmented",
                    "Value": "861980035"
                },
                {
                    "Label": "Small, Medium & Corporate Public Sector",
                    "Value": "861980037"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_endcustomersubsegmentcode",
            "Type": "picklist",
            "DisplayName": "Subsegment",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Business Consulting Firm",
                    "Value": "861980094"
                },
                {
                    "Label": "Campus Retailer",
                    "Value": "861980084"
                },
                {
                    "Label": "Major - Health",
                    "Value": "861980043"
                },
                {
                    "Label": "Major - Commercial Other",
                    "Value": "861980045"
                },
                {
                    "Label": "Consumer Electronics",
                    "Value": "861980085"
                },
                {
                    "Label": "Custom Development",
                    "Value": "861980095"
                },
                {
                    "Label": "Default OEM",
                    "Value": "861980104"
                },
                {
                    "Label": "Embedded Application",
                    "Value": "861980069"
                },
                {
                    "Label": "Embedded OEM",
                    "Value": "861980064"
                },
                {
                    "Label": "Major - Education",
                    "Value": "861980047"
                },
                {
                    "Label": "Major - Federal Government",
                    "Value": "861980048"
                },
                {
                    "Label": "Major - Government Other",
                    "Value": "861980049"
                },
                {
                    "Label": "Major - State & Local Governments",
                    "Value": "861980051"
                },
                {
                    "Label": "eTailer",
                    "Value": "861980086"
                },
                {
                    "Label": "Hardwre Manufacturer/Assembler",
                    "Value": "861980076"
                },
                {
                    "Label": "Hosting",
                    "Value": "861980068"
                },
                {
                    "Label": "Managed Service Provider",
                    "Value": "861980096"
                },
                {
                    "Label": "Marketing Agency",
                    "Value": "861980097"
                },
                {
                    "Label": "Mass Merchant",
                    "Value": "861980087"
                },
                {
                    "Label": "Mobile Application",
                    "Value": "861980070"
                },
                {
                    "Label": "MS Direct (Rslr)",
                    "Value": "861980073"
                },
                {
                    "Label": "Network Operator",
                    "Value": "861980074"
                },
                {
                    "Label": "On Premise Application",
                    "Value": "861980071"
                },
                {
                    "Label": "Operator Store",
                    "Value": "861980088"
                },
                {
                    "Label": "Original Design Manufacturer",
                    "Value": "861980077"
                },
                {
                    "Label": "Refurbisher",
                    "Value": "861980078"
                },
                {
                    "Label": "Reseller",
                    "Value": "861980080"
                },
                {
                    "Label": "Rslr Global Commercial",
                    "Value": "861980092"
                },
                {
                    "Label": "Rslr Online Stores",
                    "Value": "861980093"
                },
                {
                    "Label": "Scale Reseller",
                    "Value": "861980081"
                },
                {
                    "Label": "SM&C Commercial - Corporate",
                    "Value": "861980058"
                },
                {
                    "Label": "SM&C Commercial - SMB",
                    "Value": "861980059"
                },
                {
                    "Label": "SM&C Education - Corporate",
                    "Value": "861980060"
                },
                {
                    "Label": "SM&C Education - SMB",
                    "Value": "861980105"
                },
                {
                    "Label": "SM&C Government - Corporate",
                    "Value": "861980062"
                },
                {
                    "Label": "SM&C Government - Corporate Premier",
                    "Value": "861980061"
                },
                {
                    "Label": "SM&C Government - SMB",
                    "Value": "861980063"
                },
                {
                    "Label": "Software Asset Management",
                    "Value": "861980098"
                },
                {
                    "Label": "Specialty Retailer",
                    "Value": "861980089"
                },
                {
                    "Label": "SPLA Reseller",
                    "Value": "861980082"
                },
                {
                    "Label": "Strategic - Health",
                    "Value": "861980053"
                },
                {
                    "Label": "Strategic - Commercial Other",
                    "Value": "861980055"
                },
                {
                    "Label": "Strategic - Public Sector",
                    "Value": "861980050"
                },
                {
                    "Label": "Strategic - Retail",
                    "Value": "861980056"
                },
                {
                    "Label": "Superstore",
                    "Value": "861980090"
                },
                {
                    "Label": "System Builder",
                    "Value": "861980079"
                },
                {
                    "Label": "Systems Integrator/Outsourcer",
                    "Value": "861980099"
                },
                {
                    "Label": "Training Organization",
                    "Value": "861980100"
                },
                {
                    "Label": "Unsegmented Reseller",
                    "Value": "861980103"
                },
                {
                    "Label": "VAR",
                    "Value": "861980083"
                },
                {
                    "Label": "Virtual Network Operator",
                    "Value": "861980075"
                },
                {
                    "Label": "Warehouse/Club Store",
                    "Value": "861980091"
                },
                {
                    "Label": "Web Application",
                    "Value": "861980072"
                },
                {
                    "Label": "Web Design/Development",
                    "Value": "861980102"
                },
                {
                    "Label": "Infra: ANF",
                    "Value": "861980106"
                },
                {
                    "Label": "Strategic - Federal Government",
                    "Value": "861980107"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_englishtranslatedaccountname",
            "Type": "string",
            "DisplayName": "English Translated Account Name",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_executivesponsor",
            "Type": "lookup",
            "DisplayName": "Executive Sponsor",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_fystartmonthcode",
            "Type": "picklist",
            "DisplayName": "FY Start Month",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "January",
                    "Value": "861980000"
                },
                {
                    "Label": "February",
                    "Value": "861980001"
                },
                {
                    "Label": "March",
                    "Value": "861980002"
                },
                {
                    "Label": "April",
                    "Value": "861980003"
                },
                {
                    "Label": "May",
                    "Value": "861980004"
                },
                {
                    "Label": "June",
                    "Value": "861980005"
                },
                {
                    "Label": "July",
                    "Value": "861980006"
                },
                {
                    "Label": "August",
                    "Value": "861980007"
                },
                {
                    "Label": "September",
                    "Value": "861980008"
                },
                {
                    "Label": "October",
                    "Value": "861980009"
                },
                {
                    "Label": "November",
                    "Value": "861980010"
                },
                {
                    "Label": "December",
                    "Value": "861980011"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_gpid",
            "Type": "string",
            "DisplayName": "GPID",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_gpname",
            "Type": "string",
            "DisplayName": "GP Name",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_hardwarebuyingstrategy",
            "Type": "string",
            "DisplayName": "Hardware Buying Strategy",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_hq",
            "Type": "boolean",
            "DisplayName": "HQ Flag",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "No",
                    "Value": "0"
                },
                {
                    "Label": "Yes",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_incomingats",
            "Type": "lookup",
            "DisplayName": "IncomingATS",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_industrycode",
            "Type": "picklist",
            "DisplayName": "Industry",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Capital Markets",
                    "Value": "861980000"
                },
                {
                    "Label": "Critical Infrastructure",
                    "Value": "861980001"
                },
                {
                    "Label": "Defense Systems Integrators",
                    "Value": "861980005"
                },
                {
                    "Label": "Intelligence",
                    "Value": "861980008"
                },
                {
                    "Label": "Real Estate",
                    "Value": "861980009"
                },
                {
                    "Label": "Banking",
                    "Value": "861980013"
                },
                {
                    "Label": "Defense",
                    "Value": "861980015"
                },
                {
                    "Label": "Nonprofit & IGO",
                    "Value": "861980016"
                },
                {
                    "Label": "Pharma & Life Sciences",
                    "Value": "861980025"
                },
                {
                    "Label": "Consumer Goods",
                    "Value": "861980019"
                },
                {
                    "Label": "Discrete Manufacturing",
                    "Value": "861980004"
                },
                {
                    "Label": "Gaming",
                    "Value": "861980035"
                },
                {
                    "Label": "Health Payor",
                    "Value": "861980021"
                },
                {
                    "Label": "Health Provider",
                    "Value": "861980022"
                },
                {
                    "Label": "Higher Education",
                    "Value": "861980023"
                },
                {
                    "Label": "Insurance",
                    "Value": "861980007"
                },
                {
                    "Label": "Libraries & Museums",
                    "Value": "861980036"
                },
                {
                    "Label": "Media & Entertainment",
                    "Value": "861980024"
                },
                {
                    "Label": "N/A",
                    "Value": "861980032"
                },
                {
                    "Label": "Other - Unsegmented",
                    "Value": "861980011"
                },
                {
                    "Label": "Primary & Secondary Edu/K-12",
                    "Value": "861980028"
                },
                {
                    "Label": "Public Safety & Justice",
                    "Value": "861980041"
                },
                {
                    "Label": "Retailers",
                    "Value": "861980030"
                },
                {
                    "Label": "Telecommunications",
                    "Value": "861980038"
                },
                {
                    "Label": "Automotive & Mobility",
                    "Value": "861980042"
                },
                {
                    "Label": "Other Partner Prof Services",
                    "Value": "861980043"
                },
                {
                    "Label": "Other Professional Services",
                    "Value": "861980044"
                },
                {
                    "Label": "Process Manufacturing",
                    "Value": "861980045"
                },
                {
                    "Label": "Transport & Logistics",
                    "Value": "861980046"
                },
                {
                    "Label": "AgriBusiness",
                    "Value": "861980047"
                },
                {
                    "Label": "High Tech Software & Platform",
                    "Value": "861980048"
                },
                {
                    "Label": "Hospitality",
                    "Value": "861980049"
                },
                {
                    "Label": "Mining",
                    "Value": "861980050"
                },
                {
                    "Label": "Oil & Gas",
                    "Value": "861980051"
                },
                {
                    "Label": "Power & Utilities",
                    "Value": "861980052"
                },
                {
                    "Label": "Public Finance",
                    "Value": "861980053"
                },
                {
                    "Label": "Public Health & Social Svcs",
                    "Value": "861980054"
                },
                {
                    "Label": "Water & Sewage",
                    "Value": "861980055"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_industryverticalcode",
            "Type": "picklist",
            "DisplayName": "Vertical",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Defense",
                    "Value": "861980006"
                },
                {
                    "Label": "Defense Systems Integrators",
                    "Value": "861980033"
                },
                {
                    "Label": "Faith Based Organizations",
                    "Value": "861980068"
                },
                {
                    "Label": "Global Development & Aid",
                    "Value": "861980069"
                },
                {
                    "Label": "NonProfit Health & Human Srvc",
                    "Value": "861980081"
                },
                {
                    "Label": "IndstrlEquip&Aerospc&FarmEquip",
                    "Value": "861980082"
                },
                {
                    "Label": "Pharma & Life Sciences",
                    "Value": "861980083"
                },
                {
                    "Label": "Power & Utilities",
                    "Value": "861980084"
                },
                {
                    "Label": "Accommodation",
                    "Value": "861980000"
                },
                {
                    "Label": "Advertising",
                    "Value": "861980002"
                },
                {
                    "Label": "Architecture & Engineering",
                    "Value": "861980005"
                },
                {
                    "Label": "Banking",
                    "Value": "861980007"
                },
                {
                    "Label": "Broadcasters",
                    "Value": "861980008"
                },
                {
                    "Label": "Cable & Satellite",
                    "Value": "861980009"
                },
                {
                    "Label": "Capital Markets/Securities",
                    "Value": "861980010"
                },
                {
                    "Label": "Charities & Philanthropic",
                    "Value": "861980011"
                },
                {
                    "Label": "Chemicals",
                    "Value": "861980012"
                },
                {
                    "Label": "Cities & Regions",
                    "Value": "861980080"
                },
                {
                    "Label": "Comms&Ntwrk Equip Providers",
                    "Value": "861980039"
                },
                {
                    "Label": "Comms&Ntwrk Service Providers",
                    "Value": "861980013"
                },
                {
                    "Label": "Construction",
                    "Value": "861980025"
                },
                {
                    "Label": "Consumer Goods",
                    "Value": "861980014"
                },
                {
                    "Label": "Data",
                    "Value": "861980060"
                },
                {
                    "Label": "Film & Studio",
                    "Value": "861980017"
                },
                {
                    "Label": "Food & Beverage",
                    "Value": "861980018"
                },
                {
                    "Label": "Food Service",
                    "Value": "861980019"
                },
                {
                    "Label": "Gaming",
                    "Value": "861980070"
                },
                {
                    "Label": "General Merchandise",
                    "Value": "861980020"
                },
                {
                    "Label": "Government Social Programs",
                    "Value": "861980021"
                },
                {
                    "Label": "Grocery",
                    "Value": "861980061"
                },
                {
                    "Label": "Health & Personal Care",
                    "Value": "861980022"
                },
                {
                    "Label": "Health Payor",
                    "Value": "861980023"
                },
                {
                    "Label": "Health Provider",
                    "Value": "861980024"
                },
                {
                    "Label": "Higher Education",
                    "Value": "861980027"
                },
                {
                    "Label": "Hosters & ISPs",
                    "Value": "861980028"
                },
                {
                    "Label": "Insurance Direct",
                    "Value": "861980029"
                },
                {
                    "Label": "Insurance Other",
                    "Value": "861980059"
                },
                {
                    "Label": "Intelligence Agencies",
                    "Value": "861980062"
                },
                {
                    "Label": "International Organizations",
                    "Value": "861980063"
                },
                {
                    "Label": "IT Services",
                    "Value": "861980030"
                },
                {
                    "Label": "Justice",
                    "Value": "861980071"
                },
                {
                    "Label": "Legal Services",
                    "Value": "861980031"
                },
                {
                    "Label": "Libraries & Museums",
                    "Value": "861980032"
                },
                {
                    "Label": "Logistics & Trucking",
                    "Value": "861980034"
                },
                {
                    "Label": "Logistics Warehouse & Storage",
                    "Value": "861980056"
                },
                {
                    "Label": "Media&Entertmnt Equip Providrs",
                    "Value": "861980035"
                },
                {
                    "Label": "Membership Organizations",
                    "Value": "861980036"
                },
                {
                    "Label": "Mining",
                    "Value": "861980037"
                },
                {
                    "Label": "Motor Vehicle & Parts Dealers",
                    "Value": "861980038"
                },
                {
                    "Label": "Oil & Gas",
                    "Value": "861980040"
                },
                {
                    "Label": "Other - Unsegmented",
                    "Value": "861980041"
                },
                {
                    "Label": "Other Services",
                    "Value": "861980042"
                },
                {
                    "Label": "Outsourced Services",
                    "Value": "861980043"
                },
                {
                    "Label": "Primary & Secondary Edu/ K-12",
                    "Value": "861980045"
                },
                {
                    "Label": "Public Administration",
                    "Value": "861980046"
                },
                {
                    "Label": "Public Safety",
                    "Value": "861980048"
                },
                {
                    "Label": "Public Works & Services",
                    "Value": "861980049"
                },
                {
                    "Label": "Publishing",
                    "Value": "861980050"
                },
                {
                    "Label": "Real Estate",
                    "Value": "861980044"
                },
                {
                    "Label": "Resources & Environment",
                    "Value": "861980051"
                },
                {
                    "Label": "Retail Convenience/Gas",
                    "Value": "861980015"
                },
                {
                    "Label": "Security",
                    "Value": "861980072"
                },
                {
                    "Label": "Software",
                    "Value": "861980065"
                },
                {
                    "Label": "Specialty Retail",
                    "Value": "861980052"
                },
                {
                    "Label": "Tax & Revenue",
                    "Value": "861980054"
                },
                {
                    "Label": "Transport Services",
                    "Value": "861980055"
                },
                {
                    "Label": "Universal Banks",
                    "Value": "861980066"
                },
                {
                    "Label": "Unknown",
                    "Value": "861980067"
                },
                {
                    "Label": "Vehicle OEM",
                    "Value": "861980079"
                },
                {
                    "Label": "Vehicle Supplier",
                    "Value": "861980076"
                },
                {
                    "Label": "Water & Sewage",
                    "Value": "861980053"
                },
                {
                    "Label": "Wholesale Distribution",
                    "Value": "861980057"
                },
                {
                    "Label": "Accounting",
                    "Value": "861980085"
                },
                {
                    "Label": "AgriBusiness",
                    "Value": "861980086"
                },
                {
                    "Label": "Environmental & Animal Welfare",
                    "Value": "861980087"
                },
                {
                    "Label": "High Tech Electronics",
                    "Value": "861980088"
                },
                {
                    "Label": "Mobility Services",
                    "Value": "861980089"
                },
                {
                    "Label": "Finance, Budget & Treasury",
                    "Value": "861980090"
                },
                {
                    "Label": "HTSP Outsourced Services",
                    "Value": "861980091"
                },
                {
                    "Label": "Management Consulting",
                    "Value": "861980092"
                },
                {
                    "Label": "NonProfit Aged Care",
                    "Value": "861980093"
                },
                {
                    "Label": "Public & Community Health",
                    "Value": "861980094"
                },
                {
                    "Label": "Research",
                    "Value": "861980095"
                },
                {
                    "Label": "Semiconductor",
                    "Value": "861980096"
                },
                {
                    "Label": "Sports & Live Experiences",
                    "Value": "861980097"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_is_critical",
            "Type": "boolean",
            "DisplayName": "is_critical",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "No",
                    "Value": "0"
                },
                {
                    "Label": "Yes",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_isvtechaptitude",
            "Type": "picklist",
            "DisplayName": "ISVTechAptitude",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "High-ISV",
                    "Value": "606820000"
                },
                {
                    "Label": "High",
                    "Value": "606820001"
                },
                {
                    "Label": "Low",
                    "Value": "606820002"
                },
                {
                    "Label": "Still Assessing",
                    "Value": "606820003"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_licenseagreementtypecode",
            "Type": "picklist",
            "DisplayName": "License Agreement Type",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Non-specific",
                    "Value": "861980003"
                },
                {
                    "Label": "Academic",
                    "Value": "861980000"
                },
                {
                    "Label": "Corporate",
                    "Value": "861980001"
                },
                {
                    "Label": "Government",
                    "Value": "861980002"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_managedstatuscode",
            "Type": "picklist",
            "DisplayName": "Managed Status",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Prospect",
                    "Value": "861980000"
                },
                {
                    "Label": "Managed",
                    "Value": "861980001"
                },
                {
                    "Label": "Unmanaged",
                    "Value": "861980002"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_mcapi",
            "Type": "string",
            "DisplayName": "MCAPI",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_mcscoveragemodel",
            "Type": "string",
            "DisplayName": "MCS Coverage Model",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_mdmorganizationid",
            "Type": "string",
            "DisplayName": "MDM Org Id",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_mssalesid",
            "Type": "string",
            "DisplayName": "MS Sales Id",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_mstopparentid",
            "Type": "string",
            "DisplayName": "TPID",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_oemmanaged",
            "Type": "boolean",
            "DisplayName": "OEM Managed",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "No",
                    "Value": "0"
                },
                {
                    "Label": "Yes",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_oemorganizatontype",
            "Type": "picklist",
            "DisplayName": "OEM Organizaton Type",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Disti",
                    "Value": "861980000"
                },
                {
                    "Label": "MNA",
                    "Value": "861980001"
                },
                {
                    "Label": "IoT",
                    "Value": "861980002"
                },
                {
                    "Label": "LDP",
                    "Value": "861980003"
                },
                {
                    "Label": "ODM",
                    "Value": "861980004"
                },
                {
                    "Label": "Reseller",
                    "Value": "861980005"
                },
                {
                    "Label": "Other",
                    "Value": "861980006"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_oemsegmenttype",
            "Type": "picklist",
            "DisplayName": "OEM Segment Type",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Disti - OEM & SMSP",
                    "Value": "861980000"
                },
                {
                    "Label": "Disti - OEM",
                    "Value": "861980001"
                },
                {
                    "Label": "Disti - MNA",
                    "Value": "861980002"
                },
                {
                    "Label": "Disti - Sub",
                    "Value": "861980003"
                },
                {
                    "Label": "MNA - Corp",
                    "Value": "861980004"
                },
                {
                    "Label": "MNA - Local",
                    "Value": "861980005"
                },
                {
                    "Label": "MNA - Mobility",
                    "Value": "861980006"
                },
                {
                    "Label": "CDS IoT – Disti Global",
                    "Value": "861980007"
                },
                {
                    "Label": "CDS IoT – Disti",
                    "Value": "861980008"
                },
                {
                    "Label": "CDS IoT – LDP Direct Managed",
                    "Value": "861980009"
                },
                {
                    "Label": "CDS IoT – LDP Direct",
                    "Value": "861980010"
                },
                {
                    "Label": "CDS IoT – LDP InDirect Managed",
                    "Value": "861980011"
                },
                {
                    "Label": "CDS IoT – LDP InDirect",
                    "Value": "861980012"
                },
                {
                    "Label": "CDS IoT – Ecosystem",
                    "Value": "861980013"
                },
                {
                    "Label": "CDS IoT – Aggregator",
                    "Value": "861980014"
                },
                {
                    "Label": "LDP - Strategic",
                    "Value": "861980015"
                },
                {
                    "Label": "LDP - Depth",
                    "Value": "861980016"
                },
                {
                    "Label": "LDP - Authorized Refurbisher (MAR)",
                    "Value": "861980017"
                },
                {
                    "Label": "LDP - Breadth Refurbisher (RRP)",
                    "Value": "861980018"
                },
                {
                    "Label": "LDP - Transformational",
                    "Value": "861980019"
                },
                {
                    "Label": "ODM - Tier 1",
                    "Value": "861980020"
                },
                {
                    "Label": "ODM - Tier 2",
                    "Value": "861980021"
                },
                {
                    "Label": "ODM - Tier 3",
                    "Value": "861980022"
                },
                {
                    "Label": "Reseller - Unmanaged",
                    "Value": "861980023"
                },
                {
                    "Label": "Reseller - Managed",
                    "Value": "861980024"
                },
                {
                    "Label": "Other - Retailer",
                    "Value": "861980025"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_openleads",
            "Type": "integer",
            "DisplayName": "Open Leads",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_openleads_date",
            "Type": "datetime",
            "DisplayName": "Open Leads (Last Updated On)",
            "Format": "datetime",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_openleads_state",
            "Type": "integer",
            "DisplayName": "Open Leads (State)",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_outgoingats",
            "Type": "lookup",
            "DisplayName": "Outgoing ATS",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_parentcontactid",
            "Type": "lookup",
            "DisplayName": "Hardware Decision Maker",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_parentinglevelcode",
            "Type": "picklist",
            "DisplayName": "Parenting Level",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Child",
                    "Value": "861980001"
                },
                {
                    "Label": "Top",
                    "Value": "861980000"
                },
                {
                    "Label": "Unified",
                    "Value": "861980003"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_pccount",
            "Type": "integer",
            "DisplayName": "PC Count",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_pevcinvestors",
            "Type": "memo",
            "DisplayName": "PE/VC Investors",
            "Format": "1",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_pevcowned",
            "Type": "picklist",
            "DisplayName": "PE/VC Owned",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "No",
                    "Value": "861980000"
                },
                {
                    "Label": "Yes - Discounts Available",
                    "Value": "861980001"
                },
                {
                    "Label": "Yes - No Discounts",
                    "Value": "861980002"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_ponid",
            "Type": "string",
            "DisplayName": "PON Id",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_previousaccountowner",
            "Type": "lookup",
            "DisplayName": "Previous Account Owner",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_segmentgroup",
            "Type": "picklist",
            "DisplayName": "Segment Group",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Default OEM",
                    "Value": "865000004"
                },
                {
                    "Label": "Enterprise",
                    "Value": "865000003"
                },
                {
                    "Label": "Partner",
                    "Value": "865000006"
                },
                {
                    "Label": "Reseller",
                    "Value": "865000005"
                },
                {
                    "Label": "SMB",
                    "Value": "865000001"
                },
                {
                    "Label": "SMC Corporate",
                    "Value": "865000002"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_servercount",
            "Type": "integer",
            "DisplayName": "Server Count",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_servicesengagement",
            "Type": "boolean",
            "DisplayName": "Services Engagement",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "No",
                    "Value": "0"
                },
                {
                    "Label": "Yes",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_smartphonecount",
            "Type": "integer",
            "DisplayName": "Smartphone Count",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_subverticalcode",
            "Type": "picklist",
            "DisplayName": "SubVertical",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Accommodation",
                    "Value": "861980000"
                },
                {
                    "Label": "Accounting & Tax Services",
                    "Value": "861980001"
                },
                {
                    "Label": "Advertising",
                    "Value": "861980002"
                },
                {
                    "Label": "Aerospace",
                    "Value": "861980003"
                },
                {
                    "Label": "AgriBusiness",
                    "Value": "861980004"
                },
                {
                    "Label": "Architecture & Engineering",
                    "Value": "861980005"
                },
                {
                    "Label": "At scale SW, data & platforms",
                    "Value": "861980006"
                },
                {
                    "Label": "Banking",
                    "Value": "861980007"
                },
                {
                    "Label": "Biotech",
                    "Value": "861980008"
                },
                {
                    "Label": "Broadcasters",
                    "Value": "861980009"
                },
                {
                    "Label": "Cable & Satellite",
                    "Value": "861980010"
                },
                {
                    "Label": "Capital Markets/Securities",
                    "Value": "861980011"
                },
                {
                    "Label": "Charities & Philanthropic",
                    "Value": "861980012"
                },
                {
                    "Label": "Chemicals",
                    "Value": "861980013"
                },
                {
                    "Label": "Local & Regional Government",
                    "Value": "861980090"
                },
                {
                    "Label": "Central Government",
                    "Value": "861980091"
                },
                {
                    "Label": "Gov Ops & Infrastructure ISVs",
                    "Value": "861980092"
                },
                {
                    "Label": "Energy & Resrc Infrastructure",
                    "Value": "861980093"
                },
                {
                    "Label": "Government Operations",
                    "Value": "861980094"
                },
                {
                    "Label": "Transportation Infrastructure",
                    "Value": "861980095"
                },
                {
                    "Label": "Comms&Ntwrk Equip Providers",
                    "Value": "861980015"
                },
                {
                    "Label": "Comms&Ntwrk Service Providers",
                    "Value": "861980016"
                },
                {
                    "Label": "Construction",
                    "Value": "861980017"
                },
                {
                    "Label": "Consumer Goods",
                    "Value": "861980018"
                },
                {
                    "Label": "Defense",
                    "Value": "861980020"
                },
                {
                    "Label": "Defense Industrial Base",
                    "Value": "861980021"
                },
                {
                    "Label": "Dig Native Startups & Unicorns",
                    "Value": "861980022"
                },
                {
                    "Label": "Environmental & Animal Welfare",
                    "Value": "861980023"
                },
                {
                    "Label": "Faith Based Organizations",
                    "Value": "861980024"
                },
                {
                    "Label": "Film, Studio, Animation",
                    "Value": "861980025"
                },
                {
                    "Label": "Finance, Budget & Treasury",
                    "Value": "861980026"
                },
                {
                    "Label": "Food & Beverage",
                    "Value": "861980027"
                },
                {
                    "Label": "Food Service",
                    "Value": "861980028"
                },
                {
                    "Label": "Gaming",
                    "Value": "861980029"
                },
                {
                    "Label": "General Merchandise",
                    "Value": "861980030"
                },
                {
                    "Label": "Global Development & Aid",
                    "Value": "861980031"
                },
                {
                    "Label": "Government Social Programs",
                    "Value": "861980032"
                },
                {
                    "Label": "Grocery",
                    "Value": "861980033"
                },
                {
                    "Label": "Health & Personal Care",
                    "Value": "861980034"
                },
                {
                    "Label": "Health Payor",
                    "Value": "861980035"
                },
                {
                    "Label": "Health Provider",
                    "Value": "861980037"
                },
                {
                    "Label": "High Tech Electronics",
                    "Value": "861980038"
                },
                {
                    "Label": "Higher Education",
                    "Value": "861980039"
                },
                {
                    "Label": "Hosters & ISPs",
                    "Value": "861980040"
                },
                {
                    "Label": "Industrial Equipment&Machinery",
                    "Value": "861980041"
                },
                {
                    "Label": "Insurance Direct",
                    "Value": "861980042"
                },
                {
                    "Label": "Insurance Other",
                    "Value": "861980043"
                },
                {
                    "Label": "Intelligence Agencies",
                    "Value": "861980044"
                },
                {
                    "Label": "International Organizations",
                    "Value": "861980045"
                },
                {
                    "Label": "IT Services",
                    "Value": "861980046"
                },
                {
                    "Label": "Justice",
                    "Value": "861980047"
                },
                {
                    "Label": "Legal Services",
                    "Value": "861980048"
                },
                {
                    "Label": "Libraries & Museums",
                    "Value": "861980049"
                },
                {
                    "Label": "Logistics & Trucking",
                    "Value": "861980050"
                },
                {
                    "Label": "Logistics Warehouse & Storage",
                    "Value": "861980051"
                },
                {
                    "Label": "Managed Outsourced Services",
                    "Value": "861980052"
                },
                {
                    "Label": "Management Consulting",
                    "Value": "861980053"
                },
                {
                    "Label": "Media&Entertmnt Equip Providrs",
                    "Value": "861980054"
                },
                {
                    "Label": "MedTech",
                    "Value": "861980055"
                },
                {
                    "Label": "Membership Organizations",
                    "Value": "861980056"
                },
                {
                    "Label": "Mining",
                    "Value": "861980057"
                },
                {
                    "Label": "Mobility Services",
                    "Value": "861980058"
                },
                {
                    "Label": "Motor Vehicle & Parts Dealers",
                    "Value": "861980059"
                },
                {
                    "Label": "NonProfit Aged Care",
                    "Value": "861980060"
                },
                {
                    "Label": "NonProfit Health & Human Srvc",
                    "Value": "861980061"
                },
                {
                    "Label": "Oil & Gas",
                    "Value": "861980062"
                },
                {
                    "Label": "Other - Unsegmented",
                    "Value": "861980063"
                },
                {
                    "Label": "Other Services",
                    "Value": "861980064"
                },
                {
                    "Label": "Pharma",
                    "Value": "861980065"
                },
                {
                    "Label": "Power & Utilities",
                    "Value": "861980066"
                },
                {
                    "Label": "Primary & Secondary Edu/ K-12",
                    "Value": "861980067"
                },
                {
                    "Label": "Public & Community Health",
                    "Value": "861980068"
                },
                {
                    "Label": "Public Safety",
                    "Value": "861980070"
                },
                {
                    "Label": "Publishing",
                    "Value": "861980072"
                },
                {
                    "Label": "Real Estate",
                    "Value": "861980073"
                },
                {
                    "Label": "Research",
                    "Value": "861980074"
                },
                {
                    "Label": "Retail Convenience/Gas",
                    "Value": "861980076"
                },
                {
                    "Label": "Security",
                    "Value": "861980077"
                },
                {
                    "Label": "Semiconductor",
                    "Value": "861980078"
                },
                {
                    "Label": "Specialty Retail",
                    "Value": "861980079"
                },
                {
                    "Label": "Sports & Live Experiences",
                    "Value": "861980080"
                },
                {
                    "Label": "Staffing Services",
                    "Value": "861980081"
                },
                {
                    "Label": "Tax & Revenue",
                    "Value": "861980082"
                },
                {
                    "Label": "Transport Services",
                    "Value": "861980083"
                },
                {
                    "Label": "Universal Banks",
                    "Value": "861980084"
                },
                {
                    "Label": "Vehicle OEM",
                    "Value": "861980085"
                },
                {
                    "Label": "Vehicle Supplier",
                    "Value": "861980086"
                },
                {
                    "Label": "Water & Sewage",
                    "Value": "861980087"
                },
                {
                    "Label": "Wholesale Distribution",
                    "Value": "861980088"
                },
                {
                    "Label": "Unknown",
                    "Value": "861980089"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_tabletcount",
            "Type": "integer",
            "DisplayName": "Tablet Count",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_taxid",
            "Type": "string",
            "DisplayName": "Tax Id",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_verticalcategorycode",
            "Type": "picklist",
            "DisplayName": "Category",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Accounting",
                    "Value": "861980000"
                },
                {
                    "Label": "Accounting & Tax Services",
                    "Value": "861980235"
                },
                {
                    "Label": "Ad Agencies & Ad Sales/Service",
                    "Value": "861980001"
                },
                {
                    "Label": "Admin of Public Health Prgms",
                    "Value": "861980269"
                },
                {
                    "Label": "Aerospace",
                    "Value": "861980202"
                },
                {
                    "Label": "Agents & Brokers",
                    "Value": "861980209"
                },
                {
                    "Label": "Agriculture Administration",
                    "Value": "861980265"
                },
                {
                    "Label": "Agriculture& Agricultural Svcs",
                    "Value": "861980210"
                },
                {
                    "Label": "Air and Sea Transport Admin",
                    "Value": "861980270"
                },
                {
                    "Label": "Air Transportation",
                    "Value": "861980006"
                },
                {
                    "Label": "Apparel Manufacture",
                    "Value": "861980007"
                },
                {
                    "Label": "Apparel, Fabric, Notions Whsl",
                    "Value": "861980008"
                },
                {
                    "Label": "Architectural Services",
                    "Value": "861980177"
                },
                {
                    "Label": "Asset Management",
                    "Value": "861980010"
                },
                {
                    "Label": "At scale SW, data & platforms",
                    "Value": "861980236"
                },
                {
                    "Label": "Audio & Video Equipment",
                    "Value": "861980011"
                },
                {
                    "Label": "Auto Parts, Access., & Tires",
                    "Value": "861980012"
                },
                {
                    "Label": "Beer, Wine & Liquor",
                    "Value": "861980015"
                },
                {
                    "Label": "Behavioral Health & Disabilities",
                    "Value": "861980237"
                },
                {
                    "Label": "Benefits & Entitlements",
                    "Value": "861980267"
                },
                {
                    "Label": "Biotech",
                    "Value": "861980238"
                },
                {
                    "Label": "Biotechnology",
                    "Value": "861980016"
                },
                {
                    "Label": "Bldg & Garden Mat, Equip & Sup",
                    "Value": "861980017"
                },
                {
                    "Label": "Boat Building, Railroad&Repair",
                    "Value": "861980208"
                },
                {
                    "Label": "Books, Magazines, Newspaper",
                    "Value": "861980018"
                },
                {
                    "Label": "Cable & Other Pay TV Services",
                    "Value": "861980019"
                },
                {
                    "Label": "Central Bank/FSI Regulator",
                    "Value": "861980216"
                },
                {
                    "Label": "Central Banks",
                    "Value": "861980020"
                },
                {
                    "Label": "Central Gov Executive Branch",
                    "Value": "861980260"
                },
                {
                    "Label": "Central Gov Legislative Branch",
                    "Value": "861980279"
                },
                {
                    "Label": "Charity & Philanthropy",
                    "Value": "861980021"
                },
                {
                    "Label": "Chemical, Rubber & Plastics",
                    "Value": "861980023"
                },
                {
                    "Label": "City / Local Government",
                    "Value": "861980258"
                },
                {
                    "Label": "Clearing Infrastructures",
                    "Value": "861980056"
                },
                {
                    "Label": "Clothing&Clothing Accessories",
                    "Value": "861980024"
                },
                {
                    "Label": "Cloud / Hosting & Internet SPs",
                    "Value": "861980077"
                },
                {
                    "Label": "Combined Primary/Secondary",
                    "Value": "861980025"
                },
                {
                    "Label": "Commercial Banking",
                    "Value": "861980026"
                },
                {
                    "Label": "Commercial Contractual",
                    "Value": "861980123"
                },
                {
                    "Label": "Comms Service Provider",
                    "Value": "861980027"
                },
                {
                    "Label": "Communications Equipment",
                    "Value": "861980028"
                },
                {
                    "Label": "Computer Equipment Mfg",
                    "Value": "861980029"
                },
                {
                    "Label": "Construction of Buildings",
                    "Value": "861980030"
                },
                {
                    "Label": "Construction of Infrastructure",
                    "Value": "861980072"
                },
                {
                    "Label": "Consumer Transactional",
                    "Value": "861980178"
                },
                {
                    "Label": "Convenience/Gas",
                    "Value": "861980031"
                },
                {
                    "Label": "Cosmetics, Health & Beauty",
                    "Value": "861980033"
                },
                {
                    "Label": "Couriers & Messengers",
                    "Value": "861980034"
                },
                {
                    "Label": "Credit Unions",
                    "Value": "861980035"
                },
                {
                    "Label": "Culture, Sport & Tourism",
                    "Value": "861980271"
                },
                {
                    "Label": "Data Providers",
                    "Value": "861980217"
                },
                {
                    "Label": "Defense",
                    "Value": "861980037"
                },
                {
                    "Label": "Defense Industrial Base",
                    "Value": "861980239"
                },
                {
                    "Label": "Defense Systems Integrators",
                    "Value": "861980181"
                },
                {
                    "Label": "Department & Discount Stores",
                    "Value": "861980039"
                },
                {
                    "Label": "Dig Native Startups & Unicorns",
                    "Value": "861980240"
                },
                {
                    "Label": "Digital Currency",
                    "Value": "861980241"
                },
                {
                    "Label": "Direct Life Insurance Carriers",
                    "Value": "861980040"
                },
                {
                    "Label": "Direct Prop_Casualty Insurance",
                    "Value": "861980041"
                },
                {
                    "Label": "Direct Title Insurance Carrier",
                    "Value": "861980042"
                },
                {
                    "Label": "Distribution: Consumer Goods",
                    "Value": "861980043"
                },
                {
                    "Label": "Distribution: Industrial",
                    "Value": "861980044"
                },
                {
                    "Label": "Drinking Places",
                    "Value": "861980046"
                },
                {
                    "Label": "Drug & Pharmacies",
                    "Value": "861980047"
                },
                {
                    "Label": "Drug & Sundries Whslers",
                    "Value": "861980048"
                },
                {
                    "Label": "Economic Development",
                    "Value": "861980003"
                },
                {
                    "Label": "EDU Authority/Ministry of EDU",
                    "Value": "861980045"
                },
                {
                    "Label": "Electronic Merch Whslers",
                    "Value": "861980051"
                },
                {
                    "Label": "Electronic/Appliance Stores",
                    "Value": "861980052"
                },
                {
                    "Label": "Emergency Management",
                    "Value": "861980243"
                },
                {
                    "Label": "Energy, Water & Infrastct mgmt",
                    "Value": "861980280"
                },
                {
                    "Label": "Environment Administration",
                    "Value": "861980272"
                },
                {
                    "Label": "Environmental & Animal Welfare",
                    "Value": "861980211"
                },
                {
                    "Label": "Faith Based Organizations",
                    "Value": "861980022"
                },
                {
                    "Label": "Farm & Raw Material Whslers",
                    "Value": "861980055"
                },
                {
                    "Label": "Farm Equipment",
                    "Value": "861980204"
                },
                {
                    "Label": "Finance, Budget & Treasury",
                    "Value": "861980219"
                },
                {
                    "Label": "Fire",
                    "Value": "861980057"
                },
                {
                    "Label": "Fitness",
                    "Value": "861980220"
                },
                {
                    "Label": "Food & Drink",
                    "Value": "861980059"
                },
                {
                    "Label": "Footwear & Leather",
                    "Value": "861980060"
                },
                {
                    "Label": "Foreign Affairs",
                    "Value": "861980261"
                },
                {
                    "Label": "Full/Ltd Service Eating Places",
                    "Value": "861980062"
                },
                {
                    "Label": "Furn & Home Furnishing Whslers",
                    "Value": "861980063"
                },
                {
                    "Label": "Furniture",
                    "Value": "861980064"
                },
                {
                    "Label": "Further/Continuing Education",
                    "Value": "861980065"
                },
                {
                    "Label": "Gambling",
                    "Value": "861980066"
                },
                {
                    "Label": "Gaming",
                    "Value": "861980193"
                },
                {
                    "Label": "General Gov Administration",
                    "Value": "861980266"
                },
                {
                    "Label": "General Transportation Admin",
                    "Value": "861980263"
                },
                {
                    "Label": "Global Development & Aid",
                    "Value": "861980032"
                },
                {
                    "Label": "Gov Statistics & Census",
                    "Value": "861980273"
                },
                {
                    "Label": "Government ICT Services",
                    "Value": "861980262"
                },
                {
                    "Label": "Government Ops & Infra ISVs",
                    "Value": "861980264"
                },
                {
                    "Label": "Grocery/Specialty Foods",
                    "Value": "861980069"
                },
                {
                    "Label": "Grocery&Related Prod Wholeslrs",
                    "Value": "861980068"
                },
                {
                    "Label": "Hardware/Plumb/Heat Wholeslrs",
                    "Value": "861980070"
                },
                {
                    "Label": "Health Care Insurance",
                    "Value": "861980071"
                },
                {
                    "Label": "Health Payor",
                    "Value": "861980197"
                },
                {
                    "Label": "Health Services & Aged Care",
                    "Value": "861980118"
                },
                {
                    "Label": "Home Furnishings Specialty",
                    "Value": "861980074"
                },
                {
                    "Label": "Hospitals",
                    "Value": "861980076"
                },
                {
                    "Label": "Hotel, Motel & Casino",
                    "Value": "861980078"
                },
                {
                    "Label": "Household Appliances",
                    "Value": "861980079"
                },
                {
                    "Label": "Housing Programs",
                    "Value": "861980274"
                },
                {
                    "Label": "Indiv&Family Social Services",
                    "Value": "861980080"
                },
                {
                    "Label": "Industrial Design",
                    "Value": "861980038"
                },
                {
                    "Label": "Industrial Equipment&Machinery",
                    "Value": "861980081"
                },
                {
                    "Label": "Intelligence Agencies",
                    "Value": "861980082"
                },
                {
                    "Label": "Interior & Homeland Security",
                    "Value": "861980075"
                },
                {
                    "Label": "International Organizations",
                    "Value": "861980186"
                },
                {
                    "Label": "Investment Banking",
                    "Value": "861980084"
                },
                {
                    "Label": "Justice & Courts",
                    "Value": "861980085"
                },
                {
                    "Label": "Legal",
                    "Value": "861980086"
                },
                {
                    "Label": "Libraries / Museums",
                    "Value": "861980087"
                },
                {
                    "Label": "Liquor Wholesalers",
                    "Value": "861980088"
                },
                {
                    "Label": "Live Experiences",
                    "Value": "861980221"
                },
                {
                    "Label": "Lumber & Construction Whslers",
                    "Value": "861980089"
                },
                {
                    "Label": "Mach/Equip/Supplies Whslers",
                    "Value": "861980090"
                },
                {
                    "Label": "Mag/Optical Media Mfg/Repro",
                    "Value": "861980091"
                },
                {
                    "Label": "Managed IT Services",
                    "Value": "861980179"
                },
                {
                    "Label": "Managed Services",
                    "Value": "861980092"
                },
                {
                    "Label": "Management Consulting",
                    "Value": "861980093"
                },
                {
                    "Label": "Mass/Urban Transit Admin",
                    "Value": "861980275"
                },
                {
                    "Label": "Measure & Control Instruments",
                    "Value": "861980094"
                },
                {
                    "Label": "Media,Equip,Photo Provdr&Rentl",
                    "Value": "861980095"
                },
                {
                    "Label": "Medical Care Provider",
                    "Value": "861980268"
                },
                {
                    "Label": "Medical Device",
                    "Value": "861980096"
                },
                {
                    "Label": "MedTech",
                    "Value": "861980247"
                },
                {
                    "Label": "Member Organizations",
                    "Value": "861980097"
                },
                {
                    "Label": "Metal",
                    "Value": "861980207"
                },
                {
                    "Label": "Metal & Mineral Wholesalers",
                    "Value": "861980098"
                },
                {
                    "Label": "Mining",
                    "Value": "861980213"
                },
                {
                    "Label": "Ministry of Health",
                    "Value": "861980187"
                },
                {
                    "Label": "Misc Durable Goods Wholeslrs",
                    "Value": "861980101"
                },
                {
                    "Label": "Misc Nondurable Goods Whslers",
                    "Value": "861980102"
                },
                {
                    "Label": "Misc Specialty Retailers",
                    "Value": "861980103"
                },
                {
                    "Label": "Mobile Virtual Ntwrk Operator",
                    "Value": "861980104"
                },
                {
                    "Label": "Mobility Service Provider",
                    "Value": "861980222"
                },
                {
                    "Label": "Motion Picture,Prodution&Srvs",
                    "Value": "861980105"
                },
                {
                    "Label": "Motor Vehicles/Parts/Supplies",
                    "Value": "861980106"
                },
                {
                    "Label": "Network Equipment Providers",
                    "Value": "861980108"
                },
                {
                    "Label": "Network Service Provider",
                    "Value": "861980109"
                },
                {
                    "Label": "Non-Store Retailers",
                    "Value": "861980110"
                },
                {
                    "Label": "NonProfit Aged Care",
                    "Value": "861980223"
                },
                {
                    "Label": "NonProfit Health & Human Srvc",
                    "Value": "861980083"
                },
                {
                    "Label": "Oil & Gas",
                    "Value": "861980214"
                },
                {
                    "Label": "Optical",
                    "Value": "861980113"
                },
                {
                    "Label": "Other - Unsegmented",
                    "Value": "861980114"
                },
                {
                    "Label": "Other Accommodation",
                    "Value": "861980115"
                },
                {
                    "Label": "Other Amusement and Recreation",
                    "Value": "861980116"
                },
                {
                    "Label": "Other Direct Insurance Carrier",
                    "Value": "861980117"
                },
                {
                    "Label": "Paper & Paper Prod. Whslers",
                    "Value": "861980120"
                },
                {
                    "Label": "Payments",
                    "Value": "861980248"
                },
                {
                    "Label": "Pension Funds",
                    "Value": "861980121"
                },
                {
                    "Label": "Petroleum & Producers Whslers",
                    "Value": "861980124"
                },
                {
                    "Label": "Pharma",
                    "Value": "861980249"
                },
                {
                    "Label": "Pharma & Life Sciences",
                    "Value": "861980107"
                },
                {
                    "Label": "Pipeline",
                    "Value": "861980126"
                },
                {
                    "Label": "Police",
                    "Value": "861980127"
                },
                {
                    "Label": "Population Immunize/Vaccines",
                    "Value": "861980224"
                },
                {
                    "Label": "Postal",
                    "Value": "861980128"
                },
                {
                    "Label": "Postal Service",
                    "Value": "861980250"
                },
                {
                    "Label": "Power & Utilities",
                    "Value": "861980130"
                },
                {
                    "Label": "Primary Education / Private",
                    "Value": "861980183"
                },
                {
                    "Label": "Primary Education / Public",
                    "Value": "861980129"
                },
                {
                    "Label": "Prison",
                    "Value": "861980182"
                },
                {
                    "Label": "Private Banking",
                    "Value": "861980188"
                },
                {
                    "Label": "Private Equity",
                    "Value": "861980225"
                },
                {
                    "Label": "Private Physicians Practices",
                    "Value": "861980131"
                },
                {
                    "Label": "Private Security",
                    "Value": "861980194"
                },
                {
                    "Label": "Private Univ w Research AMC",
                    "Value": "861980251"
                },
                {
                    "Label": "Private Universities",
                    "Value": "861980184"
                },
                {
                    "Label": "Prof/Commerical Equip Whslers",
                    "Value": "861980132"
                },
                {
                    "Label": "Property Development",
                    "Value": "861980180"
                },
                {
                    "Label": "Property Management",
                    "Value": "861980140"
                },
                {
                    "Label": "Protect Vulnerable Populations",
                    "Value": "861980226"
                },
                {
                    "Label": "Public Health Programs",
                    "Value": "861980134"
                },
                {
                    "Label": "Public Health Systems",
                    "Value": "861980252"
                },
                {
                    "Label": "Public Healthcare",
                    "Value": "861980135"
                },
                {
                    "Label": "Public Univ w Research AMC",
                    "Value": "861980253"
                },
                {
                    "Label": "Public Universities",
                    "Value": "861980073"
                },
                {
                    "Label": "Pulp, Paper & Wood",
                    "Value": "861980137"
                },
                {
                    "Label": "Radio & TV",
                    "Value": "861980138"
                },
                {
                    "Label": "Rail Transportation",
                    "Value": "861980139"
                },
                {
                    "Label": "Railway Administration",
                    "Value": "861980276"
                },
                {
                    "Label": "Raw Material Producers",
                    "Value": "861980227"
                },
                {
                    "Label": "Real Estate Brokerage",
                    "Value": "861980136"
                },
                {
                    "Label": "Reg/Permit/License/Inspection",
                    "Value": "861980141"
                },
                {
                    "Label": "Reinsurance",
                    "Value": "861980228"
                },
                {
                    "Label": "Repair/Maintenance & Rental",
                    "Value": "861980143"
                },
                {
                    "Label": "Research",
                    "Value": "861980229"
                },
                {
                    "Label": "Residential Care",
                    "Value": "861980144"
                },
                {
                    "Label": "Retail Banking",
                    "Value": "861980146"
                },
                {
                    "Label": "Roadway Administration",
                    "Value": "861980277"
                },
                {
                    "Label": "Satellite Service Provider",
                    "Value": "861980145"
                },
                {
                    "Label": "Scenic & Sightseeing",
                    "Value": "861980147"
                },
                {
                    "Label": "Science & Technology",
                    "Value": "861980278"
                },
                {
                    "Label": "Secondary Education / Private",
                    "Value": "861980185"
                },
                {
                    "Label": "Secondary Education / Public",
                    "Value": "861980148"
                },
                {
                    "Label": "Securities Brokerage",
                    "Value": "861980149"
                },
                {
                    "Label": "Securities&Commodity Exchanges",
                    "Value": "861980150"
                },
                {
                    "Label": "Semicond&Elec Component Mfg",
                    "Value": "861980151"
                },
                {
                    "Label": "Soaps, Detergents & Toiletries",
                    "Value": "861980153"
                },
                {
                    "Label": "Social & Human Services",
                    "Value": "861980154"
                },
                {
                    "Label": "Software Publishers",
                    "Value": "861980156"
                },
                {
                    "Label": "Sp Goods, Hobby, Books, Music",
                    "Value": "861980157"
                },
                {
                    "Label": "Specialty Trade Contractors",
                    "Value": "861980158"
                },
                {
                    "Label": "Sport Teams & Clubs",
                    "Value": "861980230"
                },
                {
                    "Label": "Sports Technology",
                    "Value": "861980231"
                },
                {
                    "Label": "Staffing",
                    "Value": "861980160"
                },
                {
                    "Label": "Staffing Services",
                    "Value": "861980254"
                },
                {
                    "Label": "State / Regional Government",
                    "Value": "861980259"
                },
                {
                    "Label": "Stone, Clay & Glass Products",
                    "Value": "861980162"
                },
                {
                    "Label": "Streaming",
                    "Value": "861980255"
                },
                {
                    "Label": "Structural Engineering Svcs",
                    "Value": "861980009"
                },
                {
                    "Label": "Surveying Services",
                    "Value": "861980159"
                },
                {
                    "Label": "Svcs Not Elsewhere Classified",
                    "Value": "861980163"
                },
                {
                    "Label": "System Integration",
                    "Value": "861980164"
                },
                {
                    "Label": "Tax & Customs",
                    "Value": "861980165"
                },
                {
                    "Label": "Textile Mill Products",
                    "Value": "861980166"
                },
                {
                    "Label": "Theme Park",
                    "Value": "861980232"
                },
                {
                    "Label": "Tobacco",
                    "Value": "861980167"
                },
                {
                    "Label": "Transit & Ground Transport",
                    "Value": "861980168"
                },
                {
                    "Label": "Transport Support Activities",
                    "Value": "861980169"
                },
                {
                    "Label": "Trucking",
                    "Value": "861980171"
                },
                {
                    "Label": "Universal Banks",
                    "Value": "861980189"
                },
                {
                    "Label": "Unknown",
                    "Value": "861980190"
                },
                {
                    "Label": "Vehicle Dealers & Repair",
                    "Value": "861980234"
                },
                {
                    "Label": "Vehicle OEM",
                    "Value": "861980203"
                },
                {
                    "Label": "Vehicle Supplier",
                    "Value": "861980201"
                },
                {
                    "Label": "Warehouse Clubs & Supercenters",
                    "Value": "861980173"
                },
                {
                    "Label": "Warehousing & Storage",
                    "Value": "861980174"
                },
                {
                    "Label": "Water Transportation",
                    "Value": "861980175"
                },
                {
                    "Label": "Water,Sewage&Waste Disp",
                    "Value": "861980176"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "msp_verticalcode",
            "Type": "picklist",
            "DisplayName": "Vertical",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "AgriBusiness",
                    "Value": "861980000"
                },
                {
                    "Label": "At scale SW, data & platforms",
                    "Value": "861980001"
                },
                {
                    "Label": "Automotive & Mobility",
                    "Value": "861980002"
                },
                {
                    "Label": "Banking",
                    "Value": "861980003"
                },
                {
                    "Label": "Capital Markets",
                    "Value": "861980004"
                },
                {
                    "Label": "Consumer Goods",
                    "Value": "861980005"
                },
                {
                    "Label": "Gov Ops & Infrastructure",
                    "Value": "861980042"
                },
                {
                    "Label": "Defense",
                    "Value": "861980007"
                },
                {
                    "Label": "Defense Industrial Base",
                    "Value": "861980008"
                },
                {
                    "Label": "Dig Native Startups & Unicorns",
                    "Value": "861980009"
                },
                {
                    "Label": "Discrete Manufacturing",
                    "Value": "861980010"
                },
                {
                    "Label": "Gaming",
                    "Value": "861980011"
                },
                {
                    "Label": "Health Payor",
                    "Value": "861980012"
                },
                {
                    "Label": "Health Pharma",
                    "Value": "861980013"
                },
                {
                    "Label": "Health Provider",
                    "Value": "861980014"
                },
                {
                    "Label": "Higher Education",
                    "Value": "861980016"
                },
                {
                    "Label": "Hospitality",
                    "Value": "861980017"
                },
                {
                    "Label": "Insurance",
                    "Value": "861980018"
                },
                {
                    "Label": "Intelligence",
                    "Value": "861980019"
                },
                {
                    "Label": "IT Services & Business Advisory",
                    "Value": "861980020"
                },
                {
                    "Label": "Libraries & Museums",
                    "Value": "861980021"
                },
                {
                    "Label": "Media & Entertainment",
                    "Value": "861980022"
                },
                {
                    "Label": "MedTech",
                    "Value": "861980023"
                },
                {
                    "Label": "Mining",
                    "Value": "861980024"
                },
                {
                    "Label": "Nonprofit & IGO",
                    "Value": "861980025"
                },
                {
                    "Label": "Oil & Gas",
                    "Value": "861980026"
                },
                {
                    "Label": "Other - Unsegmented",
                    "Value": "861980027"
                },
                {
                    "Label": "Other Partner Prof Services",
                    "Value": "861980028"
                },
                {
                    "Label": "Other Professional Services",
                    "Value": "861980029"
                },
                {
                    "Label": "Power & Utilities",
                    "Value": "861980030"
                },
                {
                    "Label": "Primary & Secondary Edu/K-12",
                    "Value": "861980031"
                },
                {
                    "Label": "Process Manufacturing",
                    "Value": "861980032"
                },
                {
                    "Label": "Public Finance",
                    "Value": "861980033"
                },
                {
                    "Label": "Public Health & Social Svcs",
                    "Value": "861980034"
                },
                {
                    "Label": "Public Safety & Justice",
                    "Value": "861980035"
                },
                {
                    "Label": "Real Estate",
                    "Value": "861980036"
                },
                {
                    "Label": "Retailers",
                    "Value": "861980037"
                },
                {
                    "Label": "Telecommunications",
                    "Value": "861980038"
                },
                {
                    "Label": "Transport & Logistics",
                    "Value": "861980039"
                },
                {
                    "Label": "Water & Sewage",
                    "Value": "861980040"
                },
                {
                    "Label": "N/A",
                    "Value": "861980041"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "name",
            "Type": "string",
            "DisplayName": "Account Name",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "new_addaddress",
            "Type": "string",
            "DisplayName": "Add Address",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "new_updateaccount",
            "Type": "string",
            "DisplayName": "Update Account",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "numberofemployees",
            "Type": "integer",
            "DisplayName": "Number of Employees",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "onholdtime",
            "Type": "integer",
            "DisplayName": "On Hold Time (Minutes)",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "opendeals",
            "Type": "integer",
            "DisplayName": "Open Deals",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "opendeals_date",
            "Type": "datetime",
            "DisplayName": "Open Deals(Last Updated Time)",
            "Format": "datetime",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "opendeals_state",
            "Type": "integer",
            "DisplayName": "Open Deals(State)",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "openrevenue",
            "Type": "money",
            "DisplayName": "Open Est. Revenue",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "openrevenue_base",
            "Type": "money",
            "DisplayName": "Open Revenue (Base)",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "openrevenue_date",
            "Type": "datetime",
            "DisplayName": "Open Revenue(Last Updated Time)",
            "Format": "datetime",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "openrevenue_state",
            "Type": "integer",
            "DisplayName": "Open Revenue(State)",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "originatingleadid",
            "Type": "lookup",
            "DisplayName": "Originating Lead",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "overriddencreatedon",
            "Type": "datetime",
            "DisplayName": "Record Created On",
            "Format": "date",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "ownerid",
            "Type": "owner",
            "DisplayName": "Owner",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "ownershipcode",
            "Type": "picklist",
            "DisplayName": "Ownership",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Public",
                    "Value": "1"
                },
                {
                    "Label": "Private",
                    "Value": "2"
                },
                {
                    "Label": "Subsidiary",
                    "Value": "3"
                },
                {
                    "Label": "Other",
                    "Value": "4"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "owningbusinessunit",
            "Type": "lookup",
            "DisplayName": "Owning Business Unit",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "owningteam",
            "Type": "lookup",
            "DisplayName": "Owning Team",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "owninguser",
            "Type": "lookup",
            "DisplayName": "Owning User",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "parentaccountid",
            "Type": "lookup",
            "DisplayName": "Parent Account",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "participatesinworkflow",
            "Type": "boolean",
            "DisplayName": "Participates in Workflow",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "No",
                    "Value": "0"
                },
                {
                    "Label": "Yes",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "paymenttermscode",
            "Type": "picklist",
            "DisplayName": "Payment Terms",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Net 30",
                    "Value": "1"
                },
                {
                    "Label": "2% 10, Net 30",
                    "Value": "2"
                },
                {
                    "Label": "Net 45",
                    "Value": "3"
                },
                {
                    "Label": "Net 60",
                    "Value": "4"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "preferredappointmentdaycode",
            "Type": "picklist",
            "DisplayName": "Preferred Day",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Sunday",
                    "Value": "0"
                },
                {
                    "Label": "Monday",
                    "Value": "1"
                },
                {
                    "Label": "Tuesday",
                    "Value": "2"
                },
                {
                    "Label": "Wednesday",
                    "Value": "3"
                },
                {
                    "Label": "Thursday",
                    "Value": "4"
                },
                {
                    "Label": "Friday",
                    "Value": "5"
                },
                {
                    "Label": "Saturday",
                    "Value": "6"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "preferredappointmenttimecode",
            "Type": "picklist",
            "DisplayName": "Preferred Time",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Morning",
                    "Value": "1"
                },
                {
                    "Label": "Afternoon",
                    "Value": "2"
                },
                {
                    "Label": "Evening",
                    "Value": "3"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "preferredcontactmethodcode",
            "Type": "picklist",
            "DisplayName": "Preferred Method of Contact",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Any",
                    "Value": "1"
                },
                {
                    "Label": "Email",
                    "Value": "2"
                },
                {
                    "Label": "Phone",
                    "Value": "3"
                },
                {
                    "Label": "Fax",
                    "Value": "4"
                },
                {
                    "Label": "Mail",
                    "Value": "5"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "preferredequipmentid",
            "Type": "lookup",
            "DisplayName": "Preferred Facility/Equipment",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "preferredserviceid",
            "Type": "lookup",
            "DisplayName": "Preferred Service",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "preferredsystemuserid",
            "Type": "lookup",
            "DisplayName": "Preferred User",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "primarycontactid",
            "Type": "lookup",
            "DisplayName": "Primary Contact",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "primarysatoriid",
            "Type": "string",
            "DisplayName": "Primary Satori ID",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "primarytwitterid",
            "Type": "string",
            "DisplayName": "Primary Twitter ID",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "processid",
            "Type": "uniqueidentifier",
            "DisplayName": "Process",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "revenue",
            "Type": "money",
            "DisplayName": "Annual Revenue",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "revenue_base",
            "Type": "money",
            "DisplayName": "Annual Revenue (Base)",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "sharesoutstanding",
            "Type": "integer",
            "DisplayName": "Shares Outstanding",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "shippingmethodcode",
            "Type": "picklist",
            "DisplayName": "Shipping Method",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Default Value",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "sic",
            "Type": "string",
            "DisplayName": "SIC Code",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "slaid",
            "Type": "lookup",
            "DisplayName": "SLA",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "slainvokedid",
            "Type": "lookup",
            "DisplayName": "Last SLA applied",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "stageid",
            "Type": "uniqueidentifier",
            "DisplayName": "(Deprecated) Process Stage",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "statecode",
            "Type": "state",
            "DisplayName": "Status",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Active",
                    "Value": "0"
                },
                {
                    "Label": "Inactive",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "statuscode",
            "Type": "status",
            "DisplayName": "Status Reason",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Active",
                    "Value": "1"
                },
                {
                    "Label": "Inactive",
                    "Value": "2"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "stockexchange",
            "Type": "string",
            "DisplayName": "Stock Exchange",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "teamsfollowed",
            "Type": "integer",
            "DisplayName": "TeamsFollowed",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "telephone1",
            "Type": "string",
            "DisplayName": "Main Phone",
            "Format": "Phone",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "telephone2",
            "Type": "string",
            "DisplayName": "Other Phone",
            "Format": "Phone",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "telephone3",
            "Type": "string",
            "DisplayName": "Telephone 3",
            "Format": "Phone",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "territorycode",
            "Type": "picklist",
            "DisplayName": "Territory Code",
            "Format": null,
            "OptionSet": [
                {
                    "Label": "Default Value",
                    "Value": "1"
                }
            ]
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "territoryid",
            "Type": "lookup",
            "DisplayName": "Territory",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "tickersymbol",
            "Type": "string",
            "DisplayName": "Ticker Symbol",
            "Format": "TickerSymbol",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "timespentbymeonemailandmeetings",
            "Type": "string",
            "DisplayName": "Time Spent by me",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "timezoneruleversionnumber",
            "Type": "integer",
            "DisplayName": "Time Zone Rule Version Number",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "transactioncurrencyid",
            "Type": "lookup",
            "DisplayName": "Currency",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "traversedpath",
            "Type": "string",
            "DisplayName": "(Deprecated) Traversed Path",
            "Format": "Text",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "utcconversiontimezonecode",
            "Type": "integer",
            "DisplayName": "UTC Conversion Time Zone Code",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "versionnumber",
            "Type": "bigint",
            "DisplayName": "Version Number",
            "Format": null,
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "websiteurl",
            "Type": "string",
            "DisplayName": "Website",
            "Format": "Url",
            "OptionSet": null
        }
    },
    {
        "attributeDescriptor": {
            "LogicalName": "yominame",
            "Type": "string",
            "DisplayName": "Yomi Account Name",
            "Format": "PhoneticGuide",
            "OptionSet": null
        }
    }
]