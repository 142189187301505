import { IsEmpty } from '../Utils/utils'

export default function commonTooltip(cell: any, isReadOnly: boolean) {
  let divText = '---'
  if (isReadOnly) divText = '---'
  if (!IsEmpty(cell.getValue())) {
    divText = cell.getValue()
  }
  return divText
}
