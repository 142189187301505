import { IBatchResponse } from "../../Interfaces/IBatchResponse";
import { ContactCols } from "../Constants";


export function BatchResponseDecoder(strInput: string, boolWithPrefer: boolean = false) {
    let result: IBatchResponse[] = [];
    let tmpChar = '\r\n';
    let tmpBRStart: number = 0;
    let tmpBREnd: number = 0;
    let tmpSplits: string[] = [];
    //console.log(strInput);
    while ((tmpBREnd = strInput.indexOf(tmpChar, tmpBRStart)) !== -1) {
        tmpSplits.push(strInput.substring(tmpBRStart, tmpBREnd));
        tmpBRStart = tmpBREnd + 2;
    }
    for (let intStart = 0; intStart < tmpSplits.length;) {
        let strLine = tmpSplits[intStart];
        if (strLine.indexOf("--batchresponse") > -1) {
            if (!strLine.endsWith("--")) {
                let batchProcessResponse: IBatchResponse = {};
                let boolReadNextLine: boolean = true;
                while (boolReadNextLine) {
                    intStart = intStart + 1;
                    if (tmpSplits.length >= intStart - 1) {
                        strLine = tmpSplits[intStart];
                        if (strLine && strLine.indexOf("Content-ID:") > -1) {
                            batchProcessResponse.ContentId = strLine.replace("Content-ID: ", "").replace("\"", "");
                        }
                        if (strLine && strLine.indexOf("HTTP/") > -1) {
                            let strStatuses = strLine.split(' ');
                            if (strStatuses.length >= 2) {
                                batchProcessResponse.StatusCode = parseInt(strStatuses[1]);
                            }
                            if (strStatuses.length >= 3) {
                                batchProcessResponse.Status = strLine.replace(strStatuses[0] + " ", "").replace(strStatuses[1] + " ", "");
                            }
                        }
                        if (strLine && strLine.indexOf("OData-Version: 4.0") > -1) {
                            intStart = intStart + 2;
                            if (tmpSplits.length >= intStart - 1) {
                                strLine = tmpSplits[intStart];
                                if (boolWithPrefer) {
                                    intStart = intStart + 1;
                                    if (tmpSplits.length >= intStart - 1) {
                                        strLine = tmpSplits[intStart];
                                    }
                                    else {
                                        boolReadNextLine = false;
                                    }
                                }
                                if (batchProcessResponse.StatusCode && batchProcessResponse.StatusCode < 400) {
                                    if (batchProcessResponse.Status == "No Content") {
                                        batchProcessResponse.Response = strLine.replace("OData-EntityId: ", "");
                                        intStart = intStart + 2;
                                        if (strLine && strLine.indexOf("OData-Version: 4.0") > -1) {
                                            strLine = tmpSplits[intStart];
                                        }
                                        else {
                                            boolReadNextLine = false;
                                        }
                                    }
                                    else {
                                        batchProcessResponse.Response = strLine;
                                    }
                                }
                                else {
                                    batchProcessResponse.Response = strLine;
                                }
                                boolReadNextLine = false;
                            }
                            else {
                                boolReadNextLine = false;
                            }
                        }
                        else {
                            if (strLine && strLine.indexOf("X-Content-Type-Options") > -1) {
                                intStart = intStart + 2;
                                if (tmpSplits.length >= intStart - 1) {
                                    let boolContainsTotalrecordcount = false;
                                    strLine = tmpSplits[intStart];
                                    let strSearchResponse = "";
                                    while (!boolContainsTotalrecordcount) {
                                        intStart = intStart + 1;
                                        if (tmpSplits.length >= intStart - 1) {
                                            strLine = tmpSplits[intStart];
                                            if (strLine)
                                                strSearchResponse = strSearchResponse + strLine.replace('\"', '"');
                                            if (strLine && strLine.indexOf("totalrecordcount") > -1) {
                                                boolContainsTotalrecordcount = true;
                                                boolReadNextLine = false;
                                                intStart = intStart + 1;
                                                strSearchResponse = strSearchResponse + "}";
                                            }
                                        }
                                        else {
                                            boolContainsTotalrecordcount = true;
                                        }
                                    }
                                    batchProcessResponse.Response = strSearchResponse;
                                }
                                else {
                                    boolReadNextLine = false;
                                }
                            }
                            else {
                                // boolReadNextLine = false;
                            }
                        }
                    }
                    else {
                        boolReadNextLine = false;
                    }
                }
                result.push(batchProcessResponse);
            }
        }
        intStart = intStart + 1;
    }
    return result;
    //console.log(result);
}